import styles from "./OutlineFrame.module.scss";
import cn from "classnames";
import { useEffect, useRef } from "react";

const OutlineFrame = ({ reversed = false, color = "primary", value, name }) => {
    const textRef = useRef();

    useEffect(() => {
        if (textRef.current) {
            if (textRef.current.innerText.length >= 12) {
                textRef.current.classList.remove("normal");
                textRef.current.classList.add("verySmall");
            } else if (textRef.current.innerText.length >= 7) {
                textRef.current.classList.remove("normal");
                textRef.current.classList.add("small");
            }
        }
    }, [textRef]);

    return (
        <div
            className={cn(styles.container, {
                [styles.secondary]: color === "secondary",
                [styles.reversed]: reversed,
            })}>
            <div className={styles.body}>
                <p
                    ref={textRef}
                    className={cn("header normal", styles.value)}>
                    {value}
                </p>
                <p className={cn("text normal", styles.name)}>{name}</p>
            </div>
        </div>
    );
};

export default OutlineFrame;
