import styles from "./Chronicles.module.scss";
import ChroniclesItem from "../../ChroniclesItem/ChroniclesItem";
import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import bg from "../../../assets/bg2.webp";
import Search from "../../Search/Search";
import textData from "../../../assets/chronicles/items/texts";
import { Link } from "react-router-dom";
import { contentSlice } from "../../../store/reducers/contentReducer";
import { useDispatch } from "react-redux";

import prev from "../../../assets/arrowPrev.svg";

const Chronicles = () => {
    const [searchItems, setSearchItems] = useState(textData);
    const [isLoading, setIsLoading] = useState(false);

    const { updateIsIntroFinished } = contentSlice.actions;
    const dispatch = useDispatch();

    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(updateIsIntroFinished(true));
        const asyncFunc = async () => {
            setData(
                await Promise.all(
                    textData.map(async (item, ind) => {
                        let image = "";

                        await import(
                            `../../../assets/chronicles/${ind + 1}.webp`
                        ).then((img) => {
                            image = img.default;
                        });
                        return { ...item, image, id: ind + 1 };
                    })
                )
            );
        };
        asyncFunc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSearchItems(data);
    }, [data]);

    const search = (query) => {
        setIsLoading(true);
        setSearchItems(
            data.filter((item, ind) => {
                return (
                    (!isNaN(query) && Number(query) === ind + 1) ||
                    textData[ind].text
                        .toLowerCase()
                        .split(" ")
                        .indexOf(query.toLowerCase()) >= 0 ||
                    item.title
                        .toLowerCase()
                        .split(" ")
                        .indexOf(query.toLowerCase()) >= 0 ||
                    query.trim() === ""
                );
            })
        );
        setIsLoading(false);
    };

    const clear = () => {
        setSearchItems(data);
    };

    return (
        <div className={styles.container}>
            <img
                className={styles.bg}
                src={bg}
                alt="bg"
            />
            <div className={styles.button}>
                <Link to="/">
                    <img
                        src={prev}
                        alt="icon"
                    />
                    <p className="text semibig colored wide">Back to Landing</p>
                </Link>
            </div>
            <p className="header big">Chronicles</p>
            <div className={styles.search}>
                <Search
                    isLoading={isLoading}
                    onSearch={search}
                    onClear={clear}
                />
            </div>
            {!searchItems.length && !isLoading && (
                <div className={styles.empty}>
                    <p className="subheader big">There`s no matches!</p>
                </div>
            )}
            <div className={styles.chronicles}>
                {isLoading && <ColorRing />}
                {searchItems.map((el, i) => {
                    return (
                        <ChroniclesItem
                            id={el.id}
                            key={i}
                            image={el.image}
                            title={el.title}
                            index={i}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Chronicles;
