import React from "react";
import styles from "./DateTimeDisplay.module.scss";
import cn from "classnames";

const DateTimeDisplay = ({ value, type, isDays = false, isComing = false }) => {
  if (isComing)
    return (
      <div className={cn(styles.container)}>
        <p
          style={{ whiteSpace: "nowrap" }}
          className={cn("header big", styles.value)}
        >
          Already coming!
        </p>
      </div>
    );

  return (
    <div className={cn(styles.container, { [styles.days]: isDays })}>
      <p className={cn("header big", styles.value)}>
        {String(value).length === 2 ? value : "0" + String(value)}
      </p>
      <span className={cn("text small", styles.type, styles[type])}>
        {type}
      </span>
    </div>
  );
};

export default DateTimeDisplay;
