import styles from "./SlideTokenomics.module.scss";
import cn from "classnames";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../Button/Button";
import Delimiter from "../../shapes/Delimiter/Delimiter";

import arrowNext from "../../../assets/arrowNext.svg";
import arrowPrev from "../../../assets/arrowPrev.svg";
import img1 from "../../../assets/tokenomicsImg1.webp";
import img2 from "../../../assets/tokenomicsImg2.webp";
import img3 from "../../../assets/tokenomicsImg3.webp";
import bg from "../../../assets/tokenomicsBg.webp";
import frame from "../../../assets/tokenomicsFrame.png";
import tokenomicsRYO from "../../../assets/tokenomicsRYO.png";
import tokenomicsCLC from "../../../assets/tokenomicsCLC.png";
import RyoModal from "../../RyoModal/RyoModal";
import { ModalContext } from "../../../context/ModalState";
import ClcModal from "../../ClcModal/ClcModal";

const COUNT_OF_IMAGES = 3;

const SlideTokenomics = () => {
    const [currentImage, setCurrentImage] = useState(0);

    const { isMobile, isSubMobile } = useSelector(
        (state) => state.contentReducer
    );

    const { openModal } = useContext(ModalContext);

    return (
        <div
            id="tokenomics"
            className={styles.container}>
            {!isMobile && !isSubMobile && (
                <img
                    src={bg}
                    alt="background"
                    className={styles.bg}
                />
            )}
            <p className={cn(styles.header, "header big")}>Dual token system</p>
            {!isMobile && !isSubMobile && (
                <div className={styles.content}>
                    <img
                        onClick={() =>
                            setCurrentImage((prev) =>
                                prev + 1 <= COUNT_OF_IMAGES - 1
                                    ? prev + 1
                                    : COUNT_OF_IMAGES - 1
                            )
                        }
                        src={arrowNext}
                        className={cn(
                            "interactive",
                            styles.arrowNext,
                            styles.arrow,
                            {
                                [styles.active]:
                                    currentImage !== COUNT_OF_IMAGES - 1,
                            }
                        )}
                        alt="next"
                    />
                    <img
                        onClick={() =>
                            setCurrentImage((prev) =>
                                prev - 1 >= 0 ? prev - 1 : 0
                            )
                        }
                        src={arrowPrev}
                        className={cn(
                            "interactive",
                            styles.arrowPrev,
                            styles.arrow,
                            {
                                [styles.active]: currentImage !== 0,
                            }
                        )}
                        alt="prev"
                    />
                    <div className={styles.images}>
                        <img
                            src={frame}
                            alt="background"
                            className={styles.frame}
                        />
                        <img
                            className={cn(styles.image, styles.image1, {
                                [styles.active]: currentImage === 0,
                            })}
                            src={img1}
                            alt="info"
                        />
                        <img
                            className={cn(styles.image, styles.image2, {
                                [styles.active]: currentImage === 1,
                            })}
                            src={img2}
                            alt="info"
                        />
                        <img
                            className={cn(styles.image, styles.image3, {
                                [styles.active]: currentImage === 2,
                            })}
                            src={img3}
                            alt="info"
                        />
                    </div>
                    <div className={styles.pagination}>
                        {Array(COUNT_OF_IMAGES)
                            .fill("")
                            .map((_, i) => (
                                <div
                                    key={i}
                                    onClick={() => setCurrentImage(i)}
                                    className={cn(styles.index, {
                                        [styles.active]: currentImage === i,
                                    })}></div>
                            ))}
                    </div>
                </div>
            )}
            {(isMobile || isSubMobile) && (
                <div className={styles.mobileContent}>
                    <div>
                        <img
                            src={tokenomicsRYO}
                            alt="coin"
                        />
                        <p className={cn("subheader big", styles.subheader)}>
                            RYO TOKEN (In-game)
                        </p>
                        <p className={cn("text small", styles.text)}>
                            The reward system in the game is based on RYO Token.
                            We thought over the in-game economy in such a way
                            that the RYO Token would be in moderate deficit and
                            would be in demand.
                        </p>
                        <div
                            onClick={() => {
                                openModal(<RyoModal />, false, 0, false, true);
                            }}
                            className={styles.button}>
                            <Button
                                mainColor="primary"
                                type="complexShape"
                                classes="text normal wide"
                                rectsColor="#FF0049">
                                Read more
                            </Button>
                        </div>
                    </div>
                    <div>
                        <img
                            src={tokenomicsCLC}
                            alt="coin"
                        />
                        <p className={cn("subheader big", styles.subheader)}>
                            CLC TOKEN (Governance)
                        </p>
                        <p className={cn("text small", styles.text)}>
                            CLC Token is the infrastructure token of CLC web 3.0
                            ecosystem. CLC Token is used in all products of the
                            company as a means of payment and is an attractive
                            investment tool.
                        </p>
                        <div
                            onClick={() => {
                                openModal(<ClcModal />, false, 0, false, true);
                            }}
                            className={styles.button}>
                            <Button
                                mainColor="primary"
                                type="complexShape"
                                classes="text normal wide"
                                rectsColor="#FF0049">
                                Read more
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {(isMobile || isSubMobile) && (
                <Delimiter className={styles.delimiter} />
            )}
        </div>
    );
};

export default SlideTokenomics;
