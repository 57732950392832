import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isContentLoaded: false,
    isShowHeader: false,
    isModalOpened: false,
    isLoaderActive: true,
    isMobile: false,
    isSubMobile: false,
    isMenuActive: false,
    isIntroPlaying: false,
    isIntroFinished: false,
    isVideoLoaded: false,
};

export const contentSlice = createSlice({
    name: "content",
    initialState,
    reducers: {
        updateIsContentLoaded(state, action) {
            state.isContentLoaded = action.payload;
        },
        updateIsShowHeader(state, action) {
            state.isShowHeader = action.payload;
        },
        updateIsModalOpened(state, action) {
            state.isModalOpened = action.payload;
        },
        updateIsLoaderActive(state, action) {
            state.isLoaderActive = action.payload;
        },
        updateIsMobile(state, action) {
            state.isMobile = action.payload;
        },
        updateIsSubMobile(state, action) {
            state.isSubMobile = action.payload;
        },
        updateIsMenuActive(state, action) {
            state.isMenuActive = action.payload;
        },
        updateIsIntroPlaying(state, action) {
            state.isIntroPlaying = action.payload;
        },
        updateIsIntroFinished(state, action) {
            state.isIntroFinished = action.payload;
        },
        updateIsVideoLoaded(state, action) {
            state.isVideoLoaded = action.payload;
        },
    },
});

export default contentSlice.reducer;
