import styles from "./GeoMark.module.scss";
import cn from "classnames";

import bgImg from "../../assets/geomark/geomark.png";
import bottomMist from "../../assets/geomark/bottomMist.svg";
import backMist from "../../assets/geomark/backMist.svg";
import { useState } from "react";

const GeoMark = ({ year, value }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div
            onMouseEnter={() => {
                setIsActive(true);
            }}
            onMouseLeave={() => {
                setIsActive(false);
            }}
            className={cn(styles.container, { [styles.active]: isActive })}>
            <img src={bgImg} alt="background" className={styles.bg} />
            <img
                src={bottomMist}
                alt="background"
                className={styles.bottomMist}
            />
            <img src={backMist} alt="background" className={styles.backMist} />
            <div className={styles.textBlock}>
                <p className={cn("header verySmall", styles.text)}>{value}</p>
                <p className={cn("header tiny", styles.text)}>{year}</p>
            </div>
        </div>
    );
};

export default GeoMark;
