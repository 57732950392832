import { combineReducers, configureStore } from "@reduxjs/toolkit";
import contentReducer from "./reducers/contentReducer";
import timerReducer from "./reducers/timerReducer";

const rootReducer = combineReducers({
    contentReducer,
    timerReducer,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    });
};
