import React from "react";
import styles from "./Home.module.scss";
import cn from "classnames";

// import SlideIntro from "../../slides/SlideIntro/SlideIntro";
import SlideChronicles from "../../slides/SlideChronicles/SlideChronicles";
import SlideHowToPlay from "../../slides/SlideHowToPlay/SlideHowToPlay";
import SlideNFTAssets from "../../slides/SlideNFTAssets/SlideNFTAssets";
import SlideClans from "../../slides/SlideClans/SlideClans";
import SlideTokenomics from "../../slides/SlideTokenomics/SlideTokenomics";
import SlideRoadmap from "../../slides/SlideRoadmap/SlideRoadmap";
// import SlideInfo from "./components/slides/SlideInfo/SlideInfo";
import { useSelector } from "react-redux";
import Footer from "../../Footer/Footer";
import SlideWhiteList from "../../slides/SlideWhiteList/SlideWhiteList";
import SlidePartner from "../../slides/SlidePartner/SlidePartner";

// const minDeltaValue = 15;

const Home = () => {
  const { isIntroFinished, isMobile, isMenuActive } = useSelector(
    (state) => state.contentReducer
  );

  return (
    <div
      className={cn(styles.container, {
        [styles.noScroll]: !isIntroFinished || isMenuActive,
      })}
    >
      {/* Таймер Whitelist */}
      {isMobile && (
        <div className={styles.screen}>
          <SlideWhiteList />
        </div>
      )}

      <div style={{ paddingTop: 20 }} className={styles.screen}>
        <SlideClans />
      </div>
      <div className={styles.screen}>
        <SlideChronicles />
      </div>
      <div className={styles.screen}>
        <SlideHowToPlay />
      </div>
      {/* <div className={styles.screen}>
        <SlideNFTAssets />
      </div> */}
      <div className={styles.screen}>
        <SlideTokenomics />
      </div>
      <div className={styles.screen}>
        <SlidePartner />
      </div>
      <div className={styles.screen}>
        <SlideRoadmap />
      </div>
      <div className={styles.screen}>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
