import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import ChroniclesPage from "./components/pages/ChroniclesPage";
import { useLocation } from "react-router-dom";
import { contentSlice } from "./store/reducers/contentReducer";
import { useDispatch, useSelector } from "react-redux";
import ChronicleItemPage from "./components/pages/ChronicleItemPage";

const App = () => {
    const location = useLocation();

    const {
        updateIsContentLoaded,
        updateIsShowHeader,
        updateIsMobile,
        updateIsSubMobile,
    } = contentSlice.actions;

    const dispatch = useDispatch();

    const { isMenuActive, isModalOpened, isMobile } = useSelector(
        (state) => state.contentReducer
    );

    useEffect(() => {
        if (document.readyState === "complete") {
            dispatch(updateIsContentLoaded(true));
        } else {
            window.addEventListener("load", () => {
                dispatch(updateIsContentLoaded(true));
            });
            document.addEventListener("DOMContentLoaded", function () {
                window.addEventListener("load", () => {
                    dispatch(updateIsContentLoaded(true));
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        setTimeout(() => {
            Array.from(document.querySelectorAll("p.text")).forEach((el, i) => {
                el.innerHTML = el.innerText.replace(
                    /f/g,
                    '<span class="fix">f</span>'
                );
            });
        }, 300);
    }, []);

    useEffect(() => {
        if (isMenuActive || isModalOpened) {
            document.documentElement.style.overflow = "hidden";
        } else {
            document.documentElement.style.overflow = "auto";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuActive, isModalOpened]);

    useEffect(() => {
        dispatch(updateIsMobile(window.innerWidth <= 768));
        dispatch(
            updateIsSubMobile(
                window.innerWidth <= 960 && window.innerWidth > 768
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth]);

    useEffect(() => {
        dispatch(updateIsShowHeader(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/chronicles" element={<ChroniclesPage />} />
            <Route path="/chronicles/:id" element={<ChronicleItemPage />} />
        </Routes>
    );
};

export default App;
