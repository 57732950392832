import styles from "./SlideHowToPlay.module.scss";
import cn from "classnames";
import Button from "../../Button/Button";
import Delimiter from "../../shapes/Delimiter/Delimiter";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";

import arrowNext from "../../../assets/arrowNext.svg";
import arrowPrev from "../../../assets/arrowPrev.svg";
import bgImg from "../../../assets/bg1.webp";
import bambooFrame from "../../../assets/bambooFrame.webp";
import iphoneImg from "../../../assets/iphone.webp";
import iphoneMobileImg from "../../../assets/iphoneMobile.webp";
import wordImg from "../../../assets/word.svg";
import { ModalContext } from "../../../context/ModalState";

const COUNT_OF_TEXTS = 6,
    HEADERS = [
        "Participate in global multiplayer battles!",
        "Develop a military strategy together with allies",
        "To strengthen your Samurai squads, put one of the unique Heroes in charge of them",
        "Increase the number of your army",
        "Use Artifacts",
        "You decide how to manage your gaming assets",
    ],
    TEXTS = [
        `The Water Clan and the Fire Clan
participate in regular group battles on a
randomly generated game board.You act as a
general, directing your Samurai to certain
cells of the board for local clashes. If you
make better use of the features of the game
board and work as a team — you will get more
rewards.`,
        `During battles, a chat with allies opens up
for you so that you can work out a common
tactic for victory together, but no one can
stop you from acting alone. Up to you!`,
        `Heroes from generative collections can only
be mined by players and are able to go down
in history (Samurai Chronicles), thereby
gaining additional value. In addition,
Heroes strengthen your strategic potential
in group battles and can participate in 1v1
fights against other Heroes, earning the
most rewards.`,
        `You can play and earn even with one Samurai,
but if you want to develop more in the game
— there's a chance for that too! If you have
an army of at least 10 Samurai, then over
time a Hero will appear among them. The
hero, in turn, will be able to mint new
Samurai. And the increased Samurai army will
produce new Heroes even faster!`,
        `Artifacts on a Hero give benefits to all of
your Samurai, and sometimes to all of your
allies. But their main feature is that they
allow you to get more rewards for winning
battles. The higher the class of your Hero,
the more interesting Artifacts he can use!`,
        `Earned in group battles and 1v1 fights,
rewards in the form of RYO tokens can be
used to craft NFT Samurai or NFT Heroes. If
you decide not to use the tokens inside the
game, you can sell them on the exchange to
other players for USDT.`,
    ];

const SlideHowToPlay = () => {
    const [currentTextStage, setCurrentTextStage] = useState(0);

    const { isMobile, isSubMobile } = useSelector(
        (state) => state.contentReducer
    );

    const { openModal } = useContext(ModalContext);

    return (
        <div
            id="how-to-play"
            className={styles.container}>
            <img
                src={bgImg}
                alt="background"
                className={styles.bg}
            />
            <div className={styles.content}>
                {!isMobile && !isSubMobile && (
                    <>
                        {" "}
                        <img
                            onClick={() =>
                                setCurrentTextStage((prev) =>
                                    prev + 1 <= COUNT_OF_TEXTS - 1
                                        ? prev + 1
                                        : COUNT_OF_TEXTS - 1
                                )
                            }
                            src={arrowNext}
                            className={cn(
                                "interactive",
                                styles.arrowNext,
                                styles.arrow,
                                {
                                    [styles.active]:
                                        currentTextStage !== COUNT_OF_TEXTS - 1,
                                }
                            )}
                            alt="next"
                        />
                        <img
                            onClick={() =>
                                setCurrentTextStage((prev) =>
                                    prev - 1 >= 0 ? prev - 1 : 0
                                )
                            }
                            src={arrowPrev}
                            className={cn(
                                "interactive",
                                styles.arrowPrev,
                                styles.arrow,
                                {
                                    [styles.active]: currentTextStage !== 0,
                                }
                            )}
                            alt="prev"
                        />
                    </>
                )}
                {!isMobile && !isSubMobile && (
                    <img
                        src={bambooFrame}
                        alt="frame"
                        className={styles.frame}
                    />
                )}
                {!isMobile && !isSubMobile && (
                    <img
                        src={iphoneImg}
                        alt="iphone"
                        className={styles.iphone}
                    />
                )}
                {!isMobile && !isSubMobile && (
                    <img
                        src={wordImg}
                        alt="word"
                        className={styles.word}
                    />
                )}
                <p className={cn(styles.header, "header big")}>How to play</p>
                {isMobile && (
                    <img
                        src={iphoneMobileImg}
                        alt="iphone"
                        className={styles.iphoneMobile}
                    />
                )}
                {!isMobile && !isSubMobile && (
                    <>
                        <div className={styles.textBlock}>
                            <div className={styles.headers}>
                                {HEADERS.map((text, ind) => (
                                    <p
                                        key={ind}
                                        className={cn(
                                            "text big wide colored",
                                            styles.header,
                                            {
                                                [styles.active]:
                                                    currentTextStage === ind,
                                            }
                                        )}>
                                        {text}
                                    </p>
                                ))}
                            </div>
                            <div className={styles.texts}>
                                {TEXTS.map((text, ind) => (
                                    <p
                                        key={ind}
                                        className={cn(
                                            "text small",
                                            styles.text,
                                            {
                                                [styles.active]:
                                                    currentTextStage === ind,
                                            }
                                        )}>
                                        {text}
                                    </p>
                                ))}
                            </div>
                        </div>
                        <div className={styles.pagination}>
                            {Array(COUNT_OF_TEXTS)
                                .fill("")
                                .map((_, i) => (
                                    <div
                                        key={i}
                                        onClick={() => setCurrentTextStage(i)}
                                        className={cn(styles.index, {
                                            [styles.active]:
                                                currentTextStage === i,
                                        })}></div>
                                ))}
                        </div>
                    </>
                )}
                {isMobile && (
                    <>
                        <p
                            className={cn(
                                styles.headerMobile,
                                "text big wide colored"
                            )}>
                            Participate in global multiplayer battles!
                        </p>
                        <p className={cn(styles.textMobile, "text small")}>
                            Samurai Versus is a turn-based military strategy
                            based on the think-to-earn principle.
                        </p>
                        <div
                            onClick={() =>
                                openModal(
                                    <div
                                        className={cn(
                                            styles.textBlock,
                                            styles.modalTextBlock
                                        )}>
                                        {HEADERS.map((text, ind) => (
                                            <div
                                                className={styles.item}
                                                key={ind}>
                                                <p
                                                    className={cn(
                                                        "text big wide colored",
                                                        styles.header
                                                    )}>
                                                    {text}
                                                </p>
                                                <p
                                                    className={cn(
                                                        "text small",
                                                        styles.text
                                                    )}>
                                                    {TEXTS[ind]}
                                                </p>
                                                <Delimiter
                                                    cssStyles={{ left: 0 }}
                                                />
                                            </div>
                                        ))}
                                    </div>,
                                    false,
                                    0,
                                    false,
                                    true
                                )
                            }
                            className={styles.button}>
                            <Button
                                mainColor="primary"
                                type="complexShape"
                                classes="text normal wide"
                                rectsColor="#FF0049">
                                Read more
                            </Button>
                        </div>
                    </>
                )}
                {isSubMobile && (
                    <div className={styles.subContent}>
                        <div>
                            <img
                                src={iphoneMobileImg}
                                alt="iphone"
                                className={styles.iphoneMobile}
                            />
                        </div>
                        <div>
                            <p
                                className={cn(
                                    styles.headerMobile,
                                    "text big wide colored"
                                )}>
                                Participate in global multiplayer battles!
                            </p>
                            <p className={cn(styles.textMobile, "text small")}>
                                Samurai Versus is a turn-based military strategy
                                based on the think-to-earn principle.
                            </p>
                            <div
                                onClick={() =>
                                    openModal(
                                        <div
                                            className={cn(
                                                styles.textBlock,
                                                styles.modalTextBlock
                                            )}>
                                            {HEADERS.map((text, ind) => (
                                                <div
                                                    className={styles.item}
                                                    key={ind}>
                                                    <p
                                                        className={cn(
                                                            "text big wide colored",
                                                            styles.header
                                                        )}>
                                                        {text}
                                                    </p>
                                                    <p
                                                        className={cn(
                                                            "text small",
                                                            styles.text
                                                        )}>
                                                        {TEXTS[ind]}
                                                    </p>
                                                    <Delimiter
                                                        cssStyles={{ left: 0 }}
                                                    />
                                                </div>
                                            ))}
                                        </div>,
                                        false,
                                        0,
                                        false,
                                        true
                                    )
                                }
                                className={styles.button}>
                                <Button
                                    mainColor="primary"
                                    type="complexShape"
                                    classes="text normal wide"
                                    rectsColor="#FF0049">
                                    Read more
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {(isMobile || isSubMobile) && (
                <Delimiter className={styles.delimiter} />
            )}
        </div>
    );
};

export default SlideHowToPlay;
