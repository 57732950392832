import styles from "./Parallelogram.module.scss";

import cn from "classnames";

const Parallelogram = ({
    outerStyles = {},
    classes,
    width = 0.7,
    color = "white",
}) => {
    return (
        <div
            style={{
                width:
                    window.innerWidth > 1440
                        ? `${width}vw`
                        : `${width * 14.4}px`,
                background: color,
                ...outerStyles,
            }}
            className={cn(classes, styles.block)}></div>
    );
};

export default Parallelogram;
