import React, { createContext } from "react";
import { useSelector } from "react-redux";
import SlideToggle from "react-slide-toggle";

import Header from "../components/Header/Header";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import Loader from "../components/Loader/Loader";
import IntroVideo from "../components/IntroVideo/IntroVideo";
import { useLocation } from "react-router-dom";

export const HeaderContext = createContext({});

// const introVideoPromise = import("../components/IntroVideo/IntroVideo");
// const IntroVideo = React.lazy(() => introVideoPromise);

const HeaderState = ({ children }) => {
    const location = useLocation();
    const isRoot = location.pathname === "/";
    // const [isGradient, setIsGradient] = useState(false);

    // const changeHeaderGradient = (isHeaderGradient) => {
    //     setIsGradient(isHeaderGradient);
    // };

    const { isMobile, isSubMobile } = useSelector(
        (state) => state.contentReducer
    );

    return (
        <HeaderContext.Provider
            value={
                {
                    // isGradient,
                    // changeHeaderGradient,
                }
            }>
            {!isMobile && !isSubMobile ? (
                <>
                    <Header
                        toggleMenu={() => {}}
                        // isGradient={isGradient}
                    />
                    {isRoot && <IntroVideo />}
                </>
            ) : (
                <SlideToggle
                    onToggle
                    collapsed={true}
                    render={({
                        toggle,
                        toggleState,
                        setCollapsibleElement,
                    }) => {
                        return (
                            <>
                                <Header
                                    toggleState={toggleState}
                                    toggleMenu={toggle}
                                    // isGradient={isGradient}
                                />
                                <HeaderMenu
                                    toggle={toggle}
                                    ref={setCollapsibleElement}
                                />
                            </>
                        );
                    }}
                />
            )}
            <Loader />
            {children}
        </HeaderContext.Provider>
    );
};

export default HeaderState;
