import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import HeaderState from "./context/HeaderState";
import ModalState from "./context/ModalState";
import "./scss/index.scss";
import { setupStore } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = setupStore();

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ModalState>
                <HeaderState>
                    <App />
                </HeaderState>
            </ModalState>
        </Provider>
    </BrowserRouter>
);
