import styles from "./Search.module.scss";
import Button from "../Button/Button";
import { ColorRing } from "react-loader-spinner";

import strip from "../../assets/searchStrip.png";
import search from "../../assets/searchIcon.png";
import { useState } from "react";

const Search = ({ onSearch, onClear, isLoading }) => {
    const [inputValue, setInputValue] = useState("");

    return (
        <div
            onKeyDown={(e) => {
                e.key === "Enter" && onSearch(inputValue);
            }}
            className={styles.container}>
            <img
                className={styles.strip}
                src={strip}
                alt="strip"
            />
            <input
                value={inputValue}
                onInput={(e) => {
                    setInputValue(e.target.value);
                    e.target.value === "" && onClear();
                }}
                className={styles.input}
                type="text"
                placeholder="Search in Chronicles..."
            />
            <div
                onClick={() => onSearch(inputValue)}
                className={styles.button}>
                <Button type="search">
                    {isLoading ? (
                        <ColorRing />
                    ) : (
                        <img
                            src={search}
                            alt="search"
                        />
                    )}
                </Button>
            </div>
        </div>
    );
};

export default Search;
