import styles from "./ChroniclesItem.module.scss";
import cn from "classnames";

import strip from "../../assets/chroniclesStrip.svg";
import { Link } from "react-router-dom";

const ChroniclesItem = ({ image, title, id }) => {
    return (
        <Link to={`/chronicles/${id}`}>
            <div className={styles.container}>
                <img
                    className={styles.img}
                    src={image}
                    alt="thumbnail"
                />
                <img
                    src={strip}
                    className={styles.strip}
                    alt="strip"
                />
                <div className={styles.textBlock}>
                    <p className="text small colored">Chapter {id}</p>
                    <p className={cn("text big colored", styles.text)}>
                        {title}
                    </p>
                </div>
            </div>
        </Link>
    );
};

export default ChroniclesItem;
