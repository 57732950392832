import React from "react";
import { useDispatch } from "react-redux";
import { contentSlice } from "../../store/reducers/contentReducer";
import smoothScroll from "../../utils/swoothScroll";
import Button from "../Button/Button";
import styles from "./HeaderMenu.module.scss";
import cn from "classnames";

import bg from "../../assets/bg2.webp";
import discordIcon from "../../assets/sm/discord.svg";
import twitterIcon from "../../assets/sm/twitter.svg";
import zealyIcon from "../../assets/sm/zealy.svg";
import instaIcon from "../../assets/sm/insta.svg";
import tgIcon from "../../assets/sm/tg.svg";
import mediumIcon from "../../assets/sm/medium.svg";
import { useNavigate } from "react-router-dom";

const HeaderMenu = React.forwardRef(({ toggle }, setCollapsibleElement) => {
  const { updateIsMenuActive } = contentSlice.actions;
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div ref={setCollapsibleElement} className={styles.container}>
      <div className={styles.content}>
        <img src={bg} alt="bg" className={styles.bg} />
        <div className={styles.nav}>
          <div
            onClick={(e) => {
              toggle();
              dispatch(updateIsMenuActive(false));
              navigate("/");
              setTimeout(() => {
                smoothScroll("#clans", -50);
              }, 100);
            }}
            className={styles.link}
          >
            <p className="header small">Choose your clan</p>
          </div>
          <div
            onClick={() => {
              toggle();
              dispatch(updateIsMenuActive(false));
              navigate("/");
              setTimeout(() => {
                smoothScroll("#chronicles", -85);
              }, 100);
            }}
            className={styles.link}
          >
            <p className="header small">Chronicles</p>
          </div>
          <div
            onClick={() => {
              toggle();
              dispatch(updateIsMenuActive(false));
              navigate("/");
              setTimeout(() => {
                smoothScroll("#how-to-play", -45);
              }, 100);
            }}
            className={styles.link}
          >
            <p className="header small">How to play</p>
          </div>
            <div
                onClick={() => {
                    toggle();
                    dispatch(updateIsMenuActive(false));
                    navigate("/");
                    setTimeout(() => {
                        smoothScroll("#nft-assets", -100);
                    }, 100);
                }}
                className={styles.link}
            >
                <p className="header small">NFT Assets</p>
            </div>
          <div
            onClick={() => {
              toggle();
              dispatch(updateIsMenuActive(false));
              navigate("/");
              setTimeout(() => {
                smoothScroll("#tokenomics", -90);
              }, 100);
            }}
            className={styles.link}
          >
            <p className="header small">Tokenomics</p>
          </div>
            <div
                onClick={() => {
                    toggle();
                    dispatch(updateIsMenuActive(false));
                    navigate("/");
                    setTimeout(() => {
                        smoothScroll("#partner", -90);
                    }, 100);
                }}
                className={styles.link}
            >
                <p className="header small">Partners</p>
            </div>
          <div
            onClick={() => {
              toggle();
              dispatch(updateIsMenuActive(false));
              navigate("/");
              setTimeout(() => {
                smoothScroll("#roadmap", 0);
              }, 100);
            }}
            className={styles.link}
          >
            <p className="header small">Roadmap</p>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            bg="blue"
            mainColor="blue"
            alwaysActive
            type="complexShape"
            classes="text normal"
            rectsColor="#FF0049"
            onClick={() =>
              setTimeout(() => {
                window.open(
                  "https://mint.samurai-versus.io?utm_source=website&utm_medium=button&utm_campaign=genesis_mint"
                );
              })
            }
          >
            Samurai Mint
          </Button>
          <Button
            bg="blue"
            mainColor="grey"
            type="complexShape"
            classes="text normal"
            rectsColor="#FF0049"
          >
            Download app
          </Button>
          <Button
            bg="blue"
            mainColor="grey"
            type="complexShape"
            classes="text normal"
            rectsColor="#FF0049"
          >
            Marketplace
          </Button>
        </div>
        <div className={styles.pitchDeck}>
          <p
            onClick={() =>
              setTimeout(() => {
                window.open(
                  "https://drive.google.com/file/d/14pdOWif-O_rmELM0ODwfZkedpmX2-XxH/view?usp=sharing"
                );
              })
            }
            className={cn("text normal colored wide", styles.italicText)}
          >
            <i>&gt; Pitch Deck</i>
          </p>
          <p
            onClick={() =>
              setTimeout(() => {
                window.open("https://whitepaper.samurai-versus.io");
              })
            }
            className={cn("text normal colored wide", styles.italicText)}
          >
            <i>&gt; Whitepaper</i>
          </p>
          <div className={styles.smList}>
            <div className="sm">
              <p
                onClick={() =>
                  setTimeout(() => {
                    window.open("https://discord.gg/XTNrYZQcaN");
                  })
                }
                // href="https://discord.gg/XTNrYZQcaN"
                // target="_blank"
                // rel="noopener noreferrer"
                title="Discord"
              >
                <img
                  src={discordIcon}
                  alt="social media"
                  className={styles.sm}
                />
              </p>
            </div>
            <div className="sm">
              <p
                onClick={() =>
                  setTimeout(() => {
                    window.open("https://twitter.com/samurai_versus");
                  })
                }
                // href="https://twitter.com/samurai_versus"
                // target="_blank"
                // rel="noopener noreferrer"
                title="Twitter"
              >
                <img
                  src={twitterIcon}
                  alt="social media"
                  className={styles.sm}
                />
              </p>
            </div>
            <div className="sm">
              <p
                onClick={() =>
                  setTimeout(() => {
                    window.open("https://zealy.io/c/samuraiversus/questboard");
                  })
                }
                // href="https://twitter.com/samurai_versus"
                // target="_blank"
                // rel="noopener noreferrer"
                title="Zealy"
              >
                <img src={zealyIcon} alt="social media" className={styles.sm} />
              </p>
            </div>
            <div className="sm">
              <p
                onClick={() =>
                  setTimeout(() => {
                    window.open("https://www.instagram.com/samurai_versus/");
                  })
                }
                // href="https://www.instagram.com/samurai_versus/"
                // target="_blank"
                // rel="noopener noreferrer"
                title="Instagram"
              >
                <img src={instaIcon} alt="social media" className={styles.sm} />
              </p>
            </div>
            <div className="sm">
              <p
                onClick={() =>
                  setTimeout(() => {
                    window.open("https://t.me/samurai_versus");
                  })
                }
                // href="https://t.me/samurai_versus"
                // target="_blank"
                // rel="noopener noreferrer"
                title="Telegram"
              >
                <img src={tgIcon} alt="social media" className={styles.sm} />
              </p>
            </div>
            <div className="sm">
              <p
                onClick={() =>
                  setTimeout(() => {
                    window.open("https://medium.com/@Samurai-Versus");
                  })
                }
                // href="https://medium.com/@samurai.versus.game"
                // target="_blank"
                // rel="noopener noreferrer"
                title="Medium"
              >
                <img
                  src={mediumIcon}
                  alt="social media"
                  className={styles.sm}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HeaderMenu;
