import styles from "./Button.module.scss";
import cn from "classnames";

import Parallelogram from "../shapes/Parallelogram/Parallelogram";

import complexButtonMist from "../../assets/complexButtonMist.svg";
import complexButtonMistGrey from "../../assets/complexButtonMistGrey.svg";
import { useState } from "react";

const Button = ({
    children,
    classes,
    type,
    wide = false,
    alwaysActive = false,
    size = "normal",
    mainColor = "primary",
    rectsColor = "#00FFFF",
    needsMist = true,
    disabled = false,
    bg = "blue",
}) => {
    const [isActive, setIsActive] = useState(false);

    switch (type) {
        case "oneStrip":
            return (
                <div
                    onMouseEnter={() => setIsActive(true)}
                    onMouseLeave={() => setIsActive(false)}
                    className={cn(
                        styles.firstType,
                        styles.container,
                        {
                            [styles.grey]: mainColor === "grey",
                        },
                        { [styles.disabled]: disabled },
                        { [styles.greyBg]: bg === "grey" },
                        { [styles.wide]: wide }
                    )}>
                    <div className={styles.body}>
                        <p className={cn(classes, styles.buttonText)}>
                            {mainColor === "grey" && isActive
                                ? "Coming soon"
                                : children}
                        </p>
                    </div>
                    <div className={styles.strip}></div>
                </div>
            );
        case "stripAndTwoBlocks":
            return (
                <div
                    onMouseEnter={() => setIsActive(true)}
                    onMouseLeave={() => setIsActive(false)}
                    className={cn(
                        styles.secondType,
                        styles.container,
                        {
                            [styles.grey]: mainColor === "grey",
                        },
                        { [styles.greyBg]: bg === "grey" },
                        { [styles.wide]: wide },
                        { [styles.disabled]: disabled },
                        { [styles.alwaysActive]: alwaysActive }
                    )}>
                    <div className={styles.body}>
                        <p className={cn(classes, styles.buttonText)}>
                            {mainColor === "grey" && isActive
                                ? "Coming soon"
                                : children}
                        </p>
                    </div>
                    <div className={styles.strip}></div>
                    <Parallelogram classes={styles.block1} />
                    <Parallelogram classes={styles.block2} />
                </div>
            );
        case "complexShape":
            return (
                <div
                    onMouseEnter={() => setIsActive(true)}
                    onMouseLeave={() => setIsActive(false)}
                    className={cn(
                        styles.complexShape,
                        styles.container,
                        {
                            [styles.secondary]: mainColor === "secondary",
                        },
                        { [styles.wide]: size === "wide" },
                        {
                            [styles.grey]: mainColor === "grey",
                        },
                        { [styles.disabled]: disabled },
                        { [styles.greyBg]: bg === "grey" }
                    )}>
                    <div className={styles.body}>
                        <p className={cn(classes, styles.buttonText)}>
                            {mainColor === "grey" && isActive
                                ? "Coming soon"
                                : children}
                        </p>
                    </div>
                    <div className={styles.strip1}></div>
                    <div className={styles.strip2}></div>
                    <Parallelogram
                        width="2.91vw"
                        color={rectsColor}
                        classes={styles.block1}
                    />
                    <Parallelogram
                        color={rectsColor}
                        classes={styles.block2}
                    />
                    <Parallelogram
                        color={rectsColor}
                        classes={styles.block3}
                    />
                    {needsMist &&
                        (mainColor === "grey" ? (
                            <img
                                src={complexButtonMistGrey}
                                alt="mist"
                                className={styles.mist}
                            />
                        ) : (
                            <img
                                src={complexButtonMist}
                                alt="mist"
                                className={styles.mist}
                            />
                        ))}
                </div>
            );
        case "cross":
            return (
                <div
                    className={cn(styles.cross, styles.container, {
                        [styles.secondary]: mainColor === "secondary",
                    })}>
                    <div className={styles.body}>
                        <p className={cn(classes, styles.buttonText)}>
                            {children}
                        </p>
                    </div>
                    <div className={styles.strip1}></div>
                    <div className={styles.strip2}></div>
                    <Parallelogram classes={styles.block1} />
                    <Parallelogram classes={styles.block2} />
                </div>
            );
        case "search":
            return (
                <div
                    className={cn(
                        styles.cross,
                        styles.search,
                        styles.container,
                        {
                            [styles.secondary]: mainColor === "secondary",
                        }
                    )}>
                    <div className={styles.body}>
                        <p className={cn(classes, styles.buttonText)}>
                            {children}
                        </p>
                    </div>
                    <div className={styles.strip1}></div>
                    <div className={styles.strip2}></div>
                    <Parallelogram classes={styles.block1} />
                    <Parallelogram classes={styles.block2} />
                </div>
            );
        default:
            return <div></div>;
    }
};

export default Button;
