import styles from "./Header.module.scss";
import Button from "../Button/Button";
import Parallelogram from "../shapes/Parallelogram/Parallelogram";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { contentSlice } from "../../store/reducers/contentReducer";

import logoImg from "../../assets/logoImg.svg";
import discordIcon from "../../assets/sm/discord.svg";
import twitterIcon from "../../assets/sm/twitter.svg";
import zealyIcon from "../../assets/sm/zealy.svg";
import instaIcon from "../../assets/sm/insta.svg";
import tgIcon from "../../assets/sm/tg.svg";
import mediumIcon from "../../assets/sm/medium.svg";
import { useContext } from "react";
import { ModalContext } from "../../context/ModalState";
import WhiteListModal from "../WhiteListModal/WhiteListModal";

const Header = ({ isGradient, toggleMenu }) => {
    const { isShowHeader, isMobile, isMenuActive, isSubMobile } = useSelector(
        (state) => state.contentReducer
    );
    const { updateIsMenuActive } = contentSlice.actions;

    const { openModal } = useContext(ModalContext);

    const dispatch = useDispatch();

    const location = useLocation();

    if (isShowHeader) {
        return (
            <div
                className={cn(styles.container, {
                    [styles.menuActive]: isMenuActive,
                })}>
                {isGradient && !isMenuActive && (
                    <div className={styles.gradient}></div>
                )}
                <div className={styles.wrapper}>
                    <div className={styles.logo}>
                        <Link
                            onClick={(e) =>
                                location.pathname === "/" && e.preventDefault()
                            }
                            to="/">
                            <img
                                src={logoImg}
                                alt="logo"
                                className={styles.logoImg}
                            />
                        </Link>
                    </div>
                    {!isMobile && !isMenuActive && (
                        <div className={styles.info}>
                            <div
                                // onClick={() => {
                                    // openModal(
                                    //     <WhiteListModal />,
                                    //     false,
                                    //     0,
                                    //     false,
                                    //     false,
                                    //     false
                                    // );
                                // }}
                                onClick={() =>
                                    setTimeout(() => {
                                      window.open(
                                        "https://mint.samurai-versus.io?utm_source=website&utm_medium=button&utm_campaign=genesis_mint"
                                      );
                                    })
                                  }
                                className={styles.button}>
                                <Button
                                    bg={isGradient ? "grey" : "blue"}
                                    alwaysActive={true}
                                    wide={true}
                                    classes="text small"
                                    mainColor="primary"
                                    type="stripAndTwoBlocks">
                                    Samurai Mint
                                </Button>
                            </div>
                            <div className={styles.pitchDeck}>
                                <div className={styles.infoUrls}>
                                <p
                                    onClick={() =>
                                        setTimeout(() => {
                                            window.open(
                                                "https://drive.google.com/file/d/14pdOWif-O_rmELM0ODwfZkedpmX2-XxH/view?usp=sharing"
                                            );
                                        })
                                    }
                                    className={cn(
                                        "text normal colored wide",
                                        styles.italicText
                                    )}>
                                    <i>&gt; Pitch Deck</i>
                                </p>
                                <p
                                    onClick={() =>
                                        setTimeout(() => {
                                            window.open(
                                                "https://whitepaper.samurai-versus.io"
                                            );
                                        })
                                    }
                                    className={cn(
                                        "text normal colored wide",
                                        styles.italicText
                                    )}>
                                    <i>&gt; Whitepaper</i>
                                </p>
                                </div>
                                {!isSubMobile && (
                                    <div className={styles.smList}>
                                        <div className="sm">
                                            <p
                                                onClick={() =>
                                                    setTimeout(() => {
                                                        window.open(
                                                            "https://discord.gg/XTNrYZQcaN"
                                                        );
                                                    })
                                                }
                                                // href="https://discord.gg/XTNrYZQcaN"
                                                // target="_blank"
                                                // rel="noopener noreferrer"
                                                title="Discord">
                                                <img
                                                    src={discordIcon}
                                                    alt="social media"
                                                    className={styles.sm}
                                                />
                                            </p>
                                        </div>
                                        <div className="sm">
                                            <p
                                                onClick={() =>
                                                    setTimeout(() => {
                                                        window.open(
                                                            "https://twitter.com/samurai_versus"
                                                        );
                                                    })
                                                }
                                                // href="https://twitter.com/samurai_versus"
                                                // target="_blank"
                                                // rel="noopener noreferrer"
                                                title="Twitter">
                                                <img
                                                    src={twitterIcon}
                                                    alt="social media"
                                                    className={styles.sm}
                                                />
                                            </p>
                                        </div>
                                        <div className="sm">
                                            <p
                                                onClick={() =>
                                                    setTimeout(() => {
                                                        window.open(
                                                            "https://zealy.io/c/samuraiversus/questboard"
                                                        );
                                                    })
                                                }
                                                title="Zealy">
                                                <img
                                                    src={zealyIcon}
                                                    alt="social media"
                                                    className={styles.sm}
                                                />
                                            </p>
                                        </div>
                                        <div className="sm">
                                            <p
                                                onClick={() =>
                                                    setTimeout(() => {
                                                        window.open(
                                                            "https://www.instagram.com/samurai_versus/"
                                                        );
                                                    })
                                                }
                                                // href="https://www.instagram.com/samurai_versus/"
                                                // target="_blank"
                                                // rel="noopener noreferrer"
                                                title="Instagram">
                                                <img
                                                    src={instaIcon}
                                                    alt="social media"
                                                    className={styles.sm}
                                                />
                                            </p>
                                        </div>
                                        <div className="sm">
                                            <p
                                                onClick={() =>
                                                    setTimeout(() => {
                                                        window.open(
                                                            "https://t.me/samurai_versus"
                                                        );
                                                    })
                                                }
                                                // href="https://t.me/samurai_versus"
                                                // target="_blank"
                                                // rel="noopener noreferrer"
                                                title="Telegram">
                                                <img
                                                    src={tgIcon}
                                                    alt="social media"
                                                    className={styles.sm}
                                                />
                                            </p>
                                        </div>
                                        <div className="sm">
                                            <p
                                                onClick={() =>
                                                    setTimeout(() => {
                                                        window.open(
                                                            "https://medium.com/@Samurai-Versus"
                                                        );
                                                    })
                                                }
                                                // href="https://medium.com/@samurai.versus.game"
                                                // target="_blank"
                                                // rel="noopener noreferrer"
                                                title="Medium">
                                                <img
                                                    src={mediumIcon}
                                                    alt="social media"
                                                    className={styles.sm}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.buttons}>
                                <div>
                                    <Button
                                        bg={isGradient ? "grey" : "blue"}
                                        classes="text small"
                                        mainColor="grey"
                                        type="oneStrip">
                                        Download app
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        bg={isGradient ? "grey" : "blue"}
                                        classes="text small"
                                        mainColor="grey"
                                        type="stripAndTwoBlocks">
                                        Marketplace
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {(isMobile || isSubMobile) && (
                        <div
                            onClick={() => {
                                if (isMenuActive) {
                                    dispatch(updateIsMenuActive(false));
                                    toggleMenu();
                                } else {
                                    dispatch(updateIsMenuActive(true));
                                    toggleMenu();
                                }
                            }}
                            className={cn(styles.menu, {
                                [styles.active]: isMenuActive,
                            })}>
                            <div className={styles.menuEl}></div>
                            <div className={styles.menuEl}></div>
                            <div className={styles.menuEl}></div>
                            <div className={styles.menuEl}></div>
                            <div className={styles.menuEl}></div>
                            <div className={styles.menuEl}></div>
                            <div className={styles.menuEl}></div>
                            <div className={styles.menuEl}></div>
                            <div className={styles.menuEl}></div>
                        </div>
                    )}
                </div>
                <div className={styles.bottomDecor}>
                    <Parallelogram width={5.5} />
                    <Parallelogram outerStyles={{ marginLeft: "0.7vw" }} />
                    <Parallelogram />
                    <Parallelogram />
                    <div className={styles.strip}></div>
                    <Parallelogram
                        width={3.5}
                        outerStyles={{
                            marginLeft: "0.35vw",
                            marginTop: "-0.18vw",
                        }}
                    />
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default Header;
