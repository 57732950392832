import styles from "./Loader.module.scss";
import cn from "classnames";

import logoImg from "../../assets/logoLoader.webp";
import loaderMistActive from "../../assets/loaderMistActive.svg";
import loaderMistInactive from "../../assets/loaderMistInactive.svg";
import loaderInactive from "../../assets/loaderInactive.svg";
import loaderActive from "../../assets/loaderActive.svg";
import getRandomValue from "../../utils/getRandomValue";
import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { contentSlice } from "../../store/reducers/contentReducer";
import { useLocation } from "react-router-dom";

const CONFIG = {
    minTimeout: 300,
    maxTimeout: 500,
    minStep: 1,
    maxStep: 20,
};

const Loader = () => {
    const [currProgress, setCurrProgress] = useState(0);
    const [timeoutId, setTimeoutId] = useState();
    const loaderRef = useRef();
    const location = useLocation();

    const [isLoaderFinished, setIsLoaderFinished] = useState(false);
    const { isContentLoaded, isMobile, isVideoLoaded, isSubMobile } =
        useSelector((state) => state.contentReducer);
    const {
        updateIsLoaderActive,
        updateIsIntroPlaying,
        updateIsIntroFinished,
    } = contentSlice.actions;
    const dispatch = useDispatch();

    const [isOpacity, setIsOpacity] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);

    const fadeOutLoader = () => {
        setIsOpacity(0);
        setTimeout(() => {
            setIsDisplay("none");
        }, 1000);
    };

    const updateProgress = () => {
        const newValue = getRandomValue(CONFIG.minStep, CONFIG.maxStep),
            timeOut = getRandomValue(CONFIG.minTimeout, CONFIG.maxTimeout);

        setCurrProgress((prev) => {
            if (prev + newValue >= 80) {
                setIsLoaderFinished(true);
                clearTimeout(timeoutId);
                return 80;
            } else {
                return prev + newValue;
            }
        });

        setTimeoutId(setTimeout(updateProgress, timeOut));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    useEffect(() => {
        setTimeout(() => {
            updateProgress();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // console.log(
        //     isContentLoaded,
        //     isLoaderFinished,
        //     isVideoLoaded || isMobile || location.pathname !== "/",
        //     isVideoLoaded,
        //     isMobile,
        //     location.pathname !== "/"
        // );
        if (
            isContentLoaded &&
            isLoaderFinished &&
            (isVideoLoaded || isMobile || location.pathname !== "/")
        ) {
            setCurrProgress(100);
            setTimeout(() => {
                dispatch(updateIsLoaderActive(false));
                if (isMobile || isSubMobile) {
                    dispatch(updateIsIntroFinished(true));
                } else {
                    dispatch(updateIsIntroPlaying(true));
                }
            }, 400);
            clearTimeout(timeoutId);
            fadeOutLoader();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isContentLoaded, isLoaderFinished, isVideoLoaded]);

    // if (isLoaderActive) {
    return (
        <div
            style={{ opacity: isOpacity, display: isDisplay }}
            ref={loaderRef}
            className={styles.container}>
            <div className={styles.content}>
                <img
                    alt="logo"
                    src={logoImg}
                    className={styles.logo}
                />
                <div
                    style={{ opacity: `${currProgress / 100}` }}
                    className={cn(styles.mistActive, styles.mist)}>
                    <img
                        src={loaderMistActive}
                        alt="logo"
                        className={styles.mistImg}
                    />
                </div>
                <div className={cn(styles.mistInactive, styles.mist)}>
                    <img
                        src={loaderMistInactive}
                        alt="logo"
                        className={styles.mistImg}
                    />
                </div>
                <div className={styles.loader}>
                    <div className={styles.inactive}>
                        <img
                            src={loaderInactive}
                            alt="logo"
                            className={styles.inactiveImg}
                        />
                    </div>
                    <div
                        style={{ height: `${currProgress}%` }}
                        className={styles.active}>
                        <img
                            src={loaderActive}
                            alt="logo"
                            className={styles.activeImg}
                        />
                    </div>
                </div>
                <div className={styles.textBlock}>
                    <p className={cn("header white", styles.text)}>
                        NFT battle
                    </p>
                    <p className={cn("header white", styles.text)}>
                        For thinking people
                    </p>
                </div>
            </div>
        </div>
    );
    // } else {
    // return <div ref={loaderRef}></div>;
    // }
};

export default Loader;
