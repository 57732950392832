import styles from "./RyoModal.module.scss";
import cn from "classnames";
import Delimiter from "../shapes/Delimiter/Delimiter";

import ryo1 from "../../assets/tokenomicsModal/ryo1.svg";
import ryo2 from "../../assets/tokenomicsModal/ryo2.svg";
import ryo3 from "../../assets/tokenomicsModal/ryo3.svg";
import ryo4 from "../../assets/tokenomicsModal/ryo4.svg";
import ryo5 from "../../assets/tokenomicsModal/ryo5.svg";
import ryo6 from "../../assets/tokenomicsModal/ryo6.svg";
import ryo7 from "../../assets/tokenomicsModal/ryo7.svg";
import ryo8 from "../../assets/tokenomicsModal/ryo8.svg";

const RyoModal = () => {
    return (
        <div className={styles.container}>
            <div className={styles.block1}>
                <p className={cn(styles.header, "text big wide colored")}>
                    RYO Token gives players two ways to earn in the game:
                </p>
                <img
                    src={ryo1}
                    className={styles.image}
                    alt="info"
                />
                <p className={cn(styles.text, "text small")}>
                    sell RYO Token on the exchange
                </p>
                <img
                    src={ryo2}
                    className={styles.image}
                    alt="info"
                />
                <p className={cn(styles.text, "text small")}>
                    sell new minted NFT assets on the marketplace
                </p>
                <Delimiter className={styles.delimiter} />
            </div>
            <div className={styles.block2}>
                <p className={cn(styles.header, "text big wide colored")}>
                    RYO Token can be earned if you:
                </p>
                <div>
                    <div>
                        <img
                            src={ryo3}
                            className={styles.image}
                            alt="info"
                        />
                        <p className={cn(styles.text, "text small")}>
                            participate in multiplayer battles
                        </p>
                    </div>
                    <div>
                        <img
                            src={ryo4}
                            className={styles.image}
                            alt="info"
                        />
                        <p className={cn(styles.text, "text small")}>
                            participate in 1v1 Hero battles
                        </p>
                    </div>
                    <div>
                        <img
                            src={ryo5}
                            className={styles.image}
                            alt="info"
                        />
                        <p className={cn(styles.text, "text small")}>
                            stake CLC Token
                        </p>
                    </div>
                </div>
                <Delimiter className={styles.delimiter} />
            </div>
            <div className={styles.block3}>
                <p className={cn(styles.header, "text big wide colored")}>
                    RYO Token is needed for development in the game. It is used
                    (burned) when you:
                </p>
                <div>
                    <div>
                        <img
                            src={ryo6}
                            className={styles.image}
                            alt="info"
                        />
                        <p className={cn(styles.text, "text small")}>
                            heal your Samurai
                        </p>
                    </div>
                    <div>
                        <img
                            src={ryo7}
                            className={styles.image}
                            alt="info"
                        />
                        <p className={cn(styles.text, "text small")}>
                            mint NFT assets
                        </p>
                    </div>
                    <div>
                        <img
                            src={ryo8}
                            className={styles.image}
                            alt="info"
                        />
                        <p className={cn(styles.text, "text small")}>
                            buy in-game bonuses
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RyoModal;
