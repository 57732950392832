import styles from "./SlideClans.module.scss";
import cn from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import Button from "../../Button/Button";

// import clansChoise from "../../../assets/TEMP/bgChoise.png";
import lifeSamurai from "../../../assets/lifeSamurai.webp";
import deathSamurai from "../../../assets/deathSamurai.webp";
import lifeSamuraiWEBM from "../../../assets/life_animation.webm";
// import lifeSamuraiWEBM from "../../../assets/TEMP/lifeAnimation.mp4";
// import lifeSamuraiMOV from "../../../assets/life_animation.webm";
import deathSamuraiWEBM from "../../../assets/death_animation.webm";
// import deathSamuraiWEBM from "../../../assets/TEMP/deathAnimation.mp4";
// import deathSamuraiMOV from "../../../assets/death_animation.mov";
import lifeMist from "../../../assets/lifeMist.webp";
import deathMist from "../../../assets/deathMist.webp";
import bgImg1 from "../../../assets/bg2.webp";
import bgImg2 from "../../../assets/clansBg2.webp";
import choiseImg from "../../../assets/arrow.svg";
import lifeGlyph from "../../../assets/lifeGlyph.svg";
import deathGlyph from "../../../assets/deathGlyph.svg";
import lifeBambooFrame from "../../../assets/lifeBambooFrame.svg";
import deathBambooFrame from "../../../assets/deathBambooFrame.svg";
import lifeCross from "../../../assets/crossLife.svg";
import deathCross from "../../../assets/crossDeath.svg";
import OutlineFrame from "../../OutlineFrame/OutlineFrame";
import { useSelector } from "react-redux";
import Delimiter from "../../shapes/Delimiter/Delimiter";
import { ModalContext } from "../../../context/ModalState";
import { BigNumber, Contract, providers } from "ethers";

const DATA = {
  water: {
    samurai: "0",
    ryo: "0",
  },
  fire: {
    samurai: "0",
    ryo: "0",
  },
};

const SAMURAI_ABI = [
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

const fireContractAddress = "0x285c7e1eac419deab8791e600140acf4708c5607";
const waterContractAddress = "0xa3a92aba1cc7de81ef5eb356fef3ef2701e0e9e4";

const SlideClans = () => {
  const [phaze, setPhaze] = useState("choise");
  const frame1Ref = useRef();
  const frame2Ref = useRef();
  const lifeRef = useRef();
  const deathRef = useRef();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const { isMobile, isSubMobile } = useSelector(
    (state) => state.contentReducer
  );

  const { openModal } = useContext(ModalContext);

  const [fireAmount, setFireAmount] = useState(0);
  const [waterAmount, setWaterAmount] = useState(0);

  useEffect(() => {
    const fetchSupply = async () => {
      try {
        const providerUrl = "https://bsc-dataseed.binance.org/";
        const provider = new providers.JsonRpcProvider(providerUrl);

        const fireContract = new Contract(
          fireContractAddress,
          SAMURAI_ABI,
          provider
        );

        const waterContract = new Contract(
          waterContractAddress,
          SAMURAI_ABI,
          provider
        );

        const totalFireSupply = fireContract.totalSupply();
        const totalWaterSupply = waterContract.totalSupply();

        setFireAmount((await totalFireSupply).toNumber());
        setWaterAmount((await totalWaterSupply).toNumber());
      } catch (err) {
        console.log("ERROR ==> fetch supply");
        // setError(err);
      } finally {
      }
    };

    fetchSupply();
  }, []);

  return (
    <div
      onClick={(e) => {
        if (phaze === "choise") return;
        if (
          !frame1Ref.current.contains(e.target) &&
          !frame2Ref.current.contains(e.target)
        ) {
          setPhaze("choise");
        }
      }}
      id="clans"
      className={styles.container}
    >
      {!isMobile && !isSubMobile && (
        <div
          ref={frame1Ref}
          className={cn(styles.lifeInfo, {
            active: phaze === "life",
          })}
        >
          {!isMobile && (
            <>
              <div onClick={() => setPhaze("choise")} className={styles.cross}>
                <Button type="cross">
                  <img src={lifeCross} alt="cross" />
                </Button>
              </div>
              <img src={lifeBambooFrame} alt="frame" className={styles.frame} />
            </>
          )}
          <div className={styles.frameContent}>
            <p className={cn("header normal", styles.header)}>Water clan</p>
            <p className={cn("text big", styles.text)}>Recruited:</p>
            {/* <OutlineFrame value={DATA.water.samurai} name="samurai" /> */}
            <OutlineFrame value={waterAmount} name="samurai" />
            <p className={cn("text big", styles.text)}>Recruited:</p>
            <OutlineFrame value={DATA.water.ryo} name="RYO" />
            <div
              className={styles.button}
              onClick={() =>
                setTimeout(() => {
                  window.open("https://mint.samurai-versus.io", "_blank");
                })
              }
            >
              <Button
                // mainColor={isMobile ? "grey" : "grey"}
                mainColor={"blue"}
                type="complexShape"
                size="wide"
                rectsColor="#cacaca"
                needsMist={false}
              >
                {isMobile ? "Join the water clan" : "Join the clan"}
              </Button>
            </div>
          </div>
        </div>
      )}
      <img
        src={lifeGlyph}
        alt="glyph"
        className={cn(styles.lifeGlyph, { active: phaze === "life" })}
      />
      {!isMobile && !isSubMobile && (
        <div
          ref={frame2Ref}
          className={cn(styles.deathInfo, {
            active: phaze === "death",
          })}
        >
          {!isMobile && (
            <>
              <div onClick={() => setPhaze("choise")} className={styles.cross}>
                <Button mainColor="secondary" type="cross">
                  <img src={deathCross} alt="cross" />
                </Button>
              </div>
              <img
                src={deathBambooFrame}
                alt="frame"
                className={styles.frame}
              />
            </>
          )}
          <div className={styles.frameContent}>
            <p className={cn("header normal secondary", styles.header)}>
              Fire clan
            </p>
            <p className={cn("text big", styles.text)}>Recruited:</p>
            <OutlineFrame
              color="secondary"
              //   value={DATA.fire.samurai}
              value={fireAmount}
              name="samurai"
            />
            <p className={cn("text big", styles.text)}>Earned:</p>
            <OutlineFrame color="secondary" value={DATA.fire.ryo} name="RYO" />
            <div
              className={styles.button}
              onClick={() =>
                setTimeout(() => {
                  window.open("https://mint.samurai-versus.io", "_blank");
                })
              }
            >
              <Button
                type="complexShape"
                size="wide"
                mainColor={"secondary"}
                rectsColor="#FF0049"
                // mainColor={isMobile ? "grey" : "grey"}
                needsMist={false}
              >
                {isMobile ? "Join the fire clan" : "Join the clan"}
              </Button>
            </div>
          </div>
        </div>
      )}
      <img
        src={deathGlyph}
        alt="glyph"
        className={cn(styles.deathGlyph, { active: phaze === "death" })}
      />
      <img
        src={bgImg1}
        className={cn(styles.bg, styles.bg1)}
        alt="background"
      />
      {isMobile && (
        <img
          src={bgImg2}
          className={cn(styles.bg, styles.bg2)}
          alt="background"
        />
      )}
      <div
        className={cn(styles.textBlock, {
          active: phaze === "choise",
        })}
      >
        <p className={cn("header big", styles.header)}>Choose your clan</p>
        <p className={cn("text big wide", styles.bigText)}>
          Are you driven by an idea or by personal gain?
        </p>
        <p className={cn("text normal", styles.text)}>
          A Clan that is outnumbered during a fight, receives a Totem that
          equalizes the balance of power to 95%. Thus, a small Clan will be only
          5% weaker, but will be able to earn many more rewards!
        </p>
        <div className={styles.info}>
          <div
            className={cn(styles.plainInfo, styles.lifePlainInfo, {
              [styles.active]: phaze === "choise",
            })}
          >
            <div className={styles.frameContent}>
              <p className={cn("text big", styles.text)}>Recruited:</p>
              {/* <OutlineFrame value={DATA.water.samurai} name="samurai" /> */}
              <OutlineFrame value={waterAmount} name="samurai" />
              <p className={cn("text big", styles.text)}>Earned:</p>
              <OutlineFrame value={DATA.water.ryo} name="RYO" />
            </div>
          </div>
          <div
            className={cn(styles.plainInfo, styles.deathPlainInfo, {
              [styles.active]: phaze === "choise",
            })}
          >
            <div className={styles.frameContent}>
              <p className={cn("text big", styles.text, styles.noMargin)}>
                Recruited:
              </p>
              <OutlineFrame
                reversed={true}
                color="secondary"
                // value={DATA.fire.samurai}
                value={fireAmount}
                name="samurai"
              />
              <p className={cn("text big", styles.text, styles.noMargin)}>
                Earned:
              </p>
              <OutlineFrame
                reversed={true}
                color="secondary"
                value={DATA.fire.ryo}
                name="RYO"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.clans}>
        <img
          src={deathMist}
          alt="death mist"
          className={cn(
            styles.deathMist,
            { active: phaze === "death" },
            { inactive: phaze === "life" }
          )}
        />
        <img
          src={lifeMist}
          alt="life mist"
          className={cn(
            styles.lifeMist,
            { active: phaze === "life" },
            { inactive: phaze === "death" }
          )}
        />
        {isMobile && (
          <>
            <div
              onClick={() =>
                openModal(
                  <div
                    ref={frame1Ref}
                    className={cn(styles.lifeInfo, {
                      active: phaze === "life",
                    })}
                  >
                    {!isMobile && (
                      <>
                        <div
                          onClick={() => setPhaze("choise")}
                          className={styles.cross}
                        >
                          <Button type="cross">
                            <img src={lifeCross} alt="cross" />
                          </Button>
                        </div>
                        <img
                          src={lifeBambooFrame}
                          alt="frame"
                          className={styles.frame}
                        />
                      </>
                    )}
                    <div className={styles.frameContent}>
                      <p className={cn("header normal", styles.header)}>
                        Water clan
                      </p>
                      <p className={cn("text normal", styles.text)}>
                        Recruited:
                      </p>
                      {/* <OutlineFrame value={DATA.water.samurai} name="samurai" /> */}
                      <OutlineFrame value={waterAmount} name="samurai" />
                      <p className={cn("text normal", styles.text)}>Earned:</p>
                      <OutlineFrame value={DATA.water.ryo} name="RYO" />
                      <div
                        className={styles.button}
                        onClick={() =>
                          setTimeout(() => {
                            window.open(
                              "https://mint.samurai-versus.io",
                              "_blank"
                            );
                          })
                        }
                      >
                        <Button
                          // mainColor={
                          //     isMobile
                          //         ? "grey"
                          //         : "grey"
                          // }
                          mainColor="blue"
                          type="complexShape"
                          size="wide"
                          rectsColor="#cacaca"
                          classes={"text small"}
                          needsMist={false}
                        >
                          {isMobile ? "Join the water clan" : "Join the clan"}
                        </Button>
                      </div>
                    </div>
                  </div>,
                  false
                )
              }
              className={styles.lifeButton}
            >
              <Button
                classes="text small"
                needsMist={false}
                type="complexShape"
              >
                Water clan
              </Button>
            </div>
            <div
              onClick={() =>
                openModal(
                  <div
                    ref={frame2Ref}
                    className={cn(styles.deathInfo, {
                      active: phaze === "death",
                    })}
                  >
                    {!isMobile && (
                      <>
                        <div
                          onClick={() => setPhaze("choise")}
                          className={styles.cross}
                        >
                          <Button mainColor="secondary" type="cross">
                            <img src={deathCross} alt="cross" />
                          </Button>
                        </div>
                        <img
                          src={deathBambooFrame}
                          alt="frame"
                          className={styles.frame}
                        />
                      </>
                    )}
                    <div className={styles.frameContent}>
                      <p
                        className={cn("header normal secondary", styles.header)}
                      >
                        Fire clan
                      </p>
                      <p className={cn("text normal", styles.text)}>
                        Recruited:
                      </p>
                      <OutlineFrame
                        color="secondary"
                        // value={DATA.fire.samurai}
                        value={fireAmount}
                        name="samurai"
                      />
                      <p className={cn("text normal", styles.text)}>Earned:</p>
                      <OutlineFrame
                        color="secondary"
                        value={DATA.fire.ryo}
                        name="RYO"
                      />
                      <div
                        className={styles.button}
                        onClick={() =>
                          setTimeout(() => {
                            window.open(
                              "https://mint.samurai-versus.io",
                              "_blank"
                            );
                          })
                        }
                      >
                        <Button
                          type="complexShape"
                          size="wide"
                          // mainColor={
                          //     isMobile
                          //         ? "grey"
                          //         : "grey"
                          // }
                          // rectsColor="#cacaca"
                          mainColor={"secondary"}
                          rectsColor="#FF0049"
                          classes={"text small"}
                          needsMist={false}
                        >
                          {isMobile ? "Join the fire clan" : "Join the clan"}
                        </Button>
                      </div>
                    </div>
                  </div>,
                  false
                )
              }
              className={styles.deathButton}
            >
              <Button
                classes="text small"
                needsMist={false}
                type="complexShape"
                mainColor="secondary"
                rectsColor="#FF0049"
              >
                Fire clan
              </Button>
            </div>
          </>
        )}
        <div className={styles.life}>
          {!isMobile && !isSubMobile ? (
            isSafari ? (
              <div
                onClick={() => setPhaze("life")}
                className={cn(
                  styles.samuraiVideo,
                  { [styles.big]: phaze === "life" },
                  { [styles.small]: phaze === "death" }
                )}
              >
                <img
                  src={lifeSamurai}
                  alt="samurai"
                  className={styles.samuraiImg}
                />
              </div>
            ) : (
              <div
                onClick={() => setPhaze("life")}
                className={cn(
                  styles.samuraiVideo,
                  { [styles.big]: phaze === "life" },
                  { [styles.small]: phaze === "death" }
                )}
              >
                <ReactPlayer
                  ref={lifeRef}
                  onPause={
                    lifeRef.current && lifeRef.current.seekTo(0, "seconds")
                  }
                  loop={true}
                  playing={phaze === "life"}
                  url={lifeSamuraiWEBM}
                  className={styles.samurai}
                />
              </div>
            )
          ) : (
            <img
              src={lifeSamurai}
              alt="samurai"
              className={styles.samuraiImg}
            />
          )}
        </div>
        <div
          className={cn(styles.choise, {
            active: phaze === "choise",
          })}
        >
          <div
            className={cn(styles.button, styles.lifeButton, {
              active: phaze === "choise",
            })}
            onClick={() => {
              if (isSubMobile) {
                openModal(
                  <div
                    ref={frame1Ref}
                    className={cn(styles.lifeInfo, {
                      active: phaze === "life",
                    })}
                  >
                    <div className={styles.frameContent}>
                      <p className={cn("header normal", styles.header)}>
                        Water clan
                      </p>
                      <p className={cn("text normal", styles.text)}>
                        Recruited:
                      </p>
                      {/* <OutlineFrame value={DATA.water.samurai} name="samurai" /> */}
                      <OutlineFrame value={waterAmount} name="samurai" />
                      <p className={cn("text normal", styles.text)}>Earned:</p>
                      <OutlineFrame value={DATA.water.ryo} name="RYO" />
                      <div
                        className={styles.button}
                        onClick={() =>
                          setTimeout(() => {
                            window.open(
                              "https://mint.samurai-versus.io",
                              "_blank"
                            );
                          })
                        }
                      >
                        <Button
                          // mainColor={
                          //     isMobile
                          //         ? "grey"
                          //         : "grey"
                          // }
                          mainColor="blue"
                          type="complexShape"
                          // size="wide"
                          rectsColor="#cacaca"
                          classes={"text small"}
                          needsMist={false}
                        >
                          {isMobile ? "Join the water clan" : "Join the clan"}
                        </Button>
                      </div>
                    </div>
                  </div>,
                  false
                );
              } else {
                setPhaze("life");
              }
            }}
          >
            <Button classes="text small" needsMist={false} type="complexShape">
              Water clan
            </Button>
          </div>
          <img
            src={choiseImg}
            alt="choise"
            className={cn(styles.choiseImg, styles.chooseLife)}
          />
          <p className={styles.choiseText}>Choose</p>
          <img
            src={choiseImg}
            alt="choise"
            className={cn(styles.choiseImg, styles.chooseDeath)}
          />
          <div
            className={cn(styles.button, styles.deathButton, {
              active: phaze === "choise",
            })}
            onClick={() => {
              if (isSubMobile) {
                openModal(
                  <div
                    ref={frame2Ref}
                    className={cn(styles.deathInfo, {
                      active: phaze === "death",
                    })}
                  >
                    <div className={styles.frameContent}>
                      <p
                        className={cn("header normal secondary", styles.header)}
                      >
                        Fire clan
                      </p>
                      <p className={cn("text normal", styles.text)}>
                        Recruited:
                      </p>
                      <OutlineFrame
                        color="secondary"
                        // value={DATA.fire.samurai}
                        value={fireAmount}
                        name="samurai"
                      />
                      <p className={cn("text normal", styles.text)}>Earned:</p>
                      <OutlineFrame
                        color="secondary"
                        value={DATA.fire.ryo}
                        name="RYO"
                      />
                      <div
                        className={styles.button}
                        onClick={() =>
                          setTimeout(() => {
                            window.open(
                              "https://mint.samurai-versus.io",
                              "_blank"
                            );
                          })
                        }
                      >
                        <Button
                          type="complexShape"
                          // size="wide"
                          // mainColor={
                          //     isMobile
                          //         ? "grey"
                          //         : "grey"
                          // }
                          // rectsColor="#cacaca"
                          mainColor={"secondary"}
                          rectsColor="#FF0049"
                          classes={"text small"}
                          needsMist={false}
                        >
                          {isMobile ? "Join the fire clan" : "Join the clan"}
                        </Button>
                      </div>
                    </div>
                  </div>,
                  false
                );
              } else {
                setPhaze("death");
              }
            }}
          >
            <Button
              classes="text small"
              needsMist={false}
              type="complexShape"
              mainColor="secondary"
              rectsColor="#FF0049"
            >
              Fire clan
            </Button>
          </div>
        </div>
        <div className={styles.death}>
          {!isMobile && !isSubMobile ? (
            isSafari ? (
              <div
                onClick={() => setPhaze("death")}
                className={cn(
                  styles.samuraiVideo,
                  { [styles.big]: phaze === "death" },
                  { [styles.small]: phaze === "life" }
                )}
              >
                <img
                  src={deathSamurai}
                  alt="samurai"
                  className={styles.samuraiImg}
                />
              </div>
            ) : (
              <div
                onClick={() => setPhaze("death")}
                className={cn(
                  styles.samuraiVideo,
                  { [styles.big]: phaze === "death" },
                  { [styles.small]: phaze === "life" }
                )}
              >
                <ReactPlayer
                  ref={deathRef}
                  onPause={
                    deathRef.current && deathRef.current.seekTo(0, "seconds")
                  }
                  loop={true}
                  playing={phaze === "death"}
                  url={deathSamuraiWEBM}
                  className={styles.samurai}
                />
              </div>
            )
          ) : (
            <img
              src={deathSamurai}
              alt="samurai"
              className={styles.samuraiImg}
            />
          )}
        </div>
      </div>
      {(isMobile || isSubMobile) && <Delimiter className={styles.delimiter} />}
    </div>
  );
};

export default SlideClans;