import styles from "./SlideChronicles.module.scss";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "../../Button/Button";
import Delimiter from "../../shapes/Delimiter/Delimiter";

import slideImg from "../../../assets/chroniclesImg.webp";
import wordImg from "../../../assets/word.svg";

const SlideChronicles = () => {
    const { isMobile, isSubMobile } = useSelector(
        (state) => state.contentReducer
    );

    return (
        <div
            id="chronicles"
            className={styles.container}>
            <div className={styles.content}>
                {(isMobile || isSubMobile) && (
                    <p className={cn(styles.header, "header big")}>
                        Chronicles
                    </p>
                )}
                <div className={styles.imgBlock}>
                    <img
                        src={slideImg}
                        alt="cronicles"
                        className={styles.img}
                    />
                    <img
                        src={wordImg}
                        alt="word"
                        className={styles.word}
                    />
                </div>
                <div className={styles.text}>
                    {!isMobile && !isSubMobile && (
                        <p className="header big">Chronicles</p>
                    )}
                    <p className="text small">
                        For centuries, the Samurai universe has maintained a
                        balance between life and death. Commoners wallowed in
                        the pursuit of petty personal interests, and leaders
                        lost their people in senseless civil strife - but women
                        continued to give birth.
                        <br />
                        <br />
                        Why did the spirits decide to intervene and endow the
                        Fire Clan with the power of Death, and the Water Clan
                        with the power of Life, putting them against one another
                        in a decisive battle? Perhaps someday we will find out,
                        if it's not too late...
                        <br />
                        <br />
                        And now it's time for everyone to decide which side they
                        are on - the era of Heroes has come!
                    </p>
                    {!isSubMobile && (
                        <div className={styles.button}>
                            <Link to="/chronicles">
                                <Button
                                    mainColor="primary"
                                    type="complexShape"
                                    classes="text normal wide"
                                    rectsColor="#FF0049">
                                    Read the chronicles
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
                {isSubMobile && (
                    <div className={styles.button}>
                        <Link to="/chronicles">
                            <Button
                                mainColor="primary"
                                type="complexShape"
                                classes="text normal wide"
                                rectsColor="#FF0049">
                                Read the chronicles
                            </Button>
                        </Link>
                    </div>
                )}
            </div>
            {(isMobile || isSubMobile) && (
                <Delimiter className={styles.delimiter} />
            )}
        </div>
    );
};

export default SlideChronicles;
