import styles from "./Footer.module.scss";
import Button from "../Button/Button";

import bg from "../../assets/bg4.webp";
import bgMobile from "../../assets/footerMobileBg.webp";
import smoothScrool from "../../utils/swoothScroll";
import { useSelector } from "react-redux";

const Footer = () => {
    const { isMobile, isSubMobile } = useSelector(
        (state) => state.contentReducer
    );

    return (
        <div className={styles.container}>
            {!isMobile && (
                <img
                    src={bg}
                    alt="background"
                    className={styles.bg}
                />
            )}
            {isMobile && (
                <img
                    src={bgMobile}
                    alt="background"
                    className={styles.bgMobile}
                />
            )}
            <div className={styles.content}>
                <div className={styles.actions}>
                    <div className={styles.action}>
                        <p className="header normal white">Join the Clan</p>
                        <div className={styles.button}
                            onClick={() =>
                                setTimeout(() => {
                                    window.open(
                                        "https://mint.samurai-versus.io?utm_source=website&utm_medium=button&utm_campaign=genesis_mint"
                                    );
                                })
                            }
                        >
                            <Button
                                // bg="grey"
                                // mainColor="grey"
                                mainColor="blue"
                                type="complexShape"
                                classes="text normal"
                                rectsColor="#FF0049">
                                {/* Marketplace */}
                                Samurai Mint
                            </Button>
                        </div>
                    </div>
                    <div className={styles.action}>
                        <p className="header normal white">
                            Play from your phone
                        </p>
                        <div className={styles.button}>
                            <Button
                                bg="grey"
                                mainColor="grey"
                                type="complexShape"
                                classes="text normal"
                                rectsColor="#FF0049">
                                Download app
                            </Button>
                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <div className={styles.nav}>
                        <div
                            onClick={() =>
                                smoothScrool(
                                    "#clans",
                                    window.innerWidth > 1440
                                        ? (-20 / 1536) * window.innerWidth
                                        : -20
                                )
                            }
                            className={styles.link}>
                            <p className="text wide normal colored transform">
                                Choose your clan
                            </p>
                        </div>
                        <div
                            onClick={() =>
                                smoothScrool(
                                    "#chronicles",
                                    window.innerWidth > 1440
                                        ? (-80 / 1536) * window.innerWidth
                                        : -80
                                )
                            }
                            className={styles.link}>
                            <p className="text wide normal colored transform">
                                Chronicles
                            </p>
                        </div>
                        <div
                            onClick={() =>
                                smoothScrool(
                                    "#how-to-play",
                                    window.innerWidth > 1440
                                        ? (55 / 1536) * window.innerWidth
                                        : -55
                                )
                            }
                            className={styles.link}>
                            <p className="text wide normal colored transform">
                                How to play
                            </p>
                        </div>
                        <div
                            onClick={() =>
                                smoothScrool(
                                    "#nft-assets",
                                    window.innerWidth > 1440
                                        ? (-100 / 1536) * window.innerWidth
                                        : -100
                                )
                            }
                            className={styles.link}>
                            <p className="text wide normal colored transform">
                                NFT Assets
                            </p>
                        </div>
                        <div
                            onClick={() =>
                                smoothScrool(
                                    "#tokenomics",
                                    window.innerWidth > 1440
                                        ? (-105 / 1536) * window.innerWidth
                                        : -105
                                )
                            }
                            className={styles.link}>
                            <p className="text wide normal colored transform">
                                Tokenomics
                            </p>
                        </div>
                        <div
                            onClick={() =>
                                smoothScrool(
                                    "#partner",
                                    window.innerWidth > 1440
                                        ? (160 / 1536) * window.innerWidth
                                        : isSubMobile
                                            ? 120
                                            : 240
                                )
                            }
                            className={styles.link}>
                            <p className="text wide normal colored transform">
                                Partners
                            </p>
                        </div>
                        <div
                            onClick={() =>
                                smoothScrool(
                                    "#roadmap",
                                    window.innerWidth > 1440
                                        ? (340 / 1536) * window.innerWidth
                                        : isSubMobile
                                        ? 120
                                        : 240
                                )
                            }
                            className={styles.link}>
                            <p className="text wide normal colored transform">
                                Roadmap
                            </p>
                        </div>
                    </div>
                )}

                <div className={styles.copyright}>
                    <p className="text small transform">
                        ©{new Date().getFullYear()} • SAMURAI VERSUS • ALL RIGHT
                        RESERVED
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
