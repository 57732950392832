import React, { useEffect, useRef, useState } from "react";
import styles from "./IntroVideo.module.scss";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { contentSlice } from "../../store/reducers/contentReducer";
import Button from "../Button/Button";

import intro from "../../assets/intro.mp4";

const IntroVideo = () => {
    const { isLoaderActive, isIntroFinished } = useSelector(
        (state) => state.contentReducer
    );
    const { updateIsIntroFinished, updateIsShowHeader, updateIsVideoLoaded } =
        contentSlice.actions;
    const dispatch = useDispatch();
    const videoRef = useRef();

    const [isShowContent, setIsShowContent] = useState(false);
    const [isDisplay, setIsDisplay] = useState(true);
    const [isZIndex, setIsZIndex] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const isFirefox = /^((?!chrome|android).)*firefox/i.test(
        navigator.userAgent
    );

    const continueVideo = () => {
        !isFirefox && videoRef.current.play();
        isFirefox &&
            (() => {
                setIsDisplay(false);
                setTimeout(() => {
                    setIsZIndex(true);
                    dispatch(updateIsIntroFinished(true));
                    dispatch(updateIsShowHeader(true));
                }, 500);
            })();
    };

    useEffect(() => {
        if (videoRef.current && window.innerWidth > 960) {
            !isPaused && videoRef.current.load();
            videoRef.current.ontimeupdate = () => {
                if (videoRef.current.currentTime >= 7.5) {
                    !isFirefox &&
                        (() => {
                            setIsDisplay(false);
                            setTimeout(() => {
                                setIsZIndex(true);
                                dispatch(updateIsIntroFinished(true));
                                dispatch(updateIsShowHeader(true));
                            }, 500);
                        })();
                } else if (videoRef.current.currentTime >= 6) {
                    if (!isFirefox && !isPaused) {
                        setIsPaused(true);
                        videoRef.current.pause();
                    }
                } else if (videoRef.current.currentTime >= 4) {
                    setIsShowContent(true);
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef, isPaused]);

    useEffect(() => {
        const func = async () => {
            if (videoRef.current) {
                videoRef.current.oncanplaythrough = (e) => {
                    dispatch(updateIsVideoLoaded(true));
                };
                videoRef.current.pause();
            }
            if (videoRef.current && !isLoaderActive) {
                videoRef.current.play();
            }
        };
        func();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef, isLoaderActive]);

    if (!isIntroFinished) {
        return (
            <div
                className={cn(
                    styles.container,
                    { [styles.display]: isDisplay },
                    { [styles.zIndex]: isZIndex }
                )}>
                <video
                    ref={videoRef}
                    autoPlay={true}
                    preload="auto"
                    muted={true}
                    className={styles.video}
                    src={intro}></video>
                {/* <ReactPlayer
                autoPlay
                preload="auto"
                onProgress={(e) => {
                    if (e.playedSeconds >= 4) {
                        setIsShowContent(true);
                    }
                }}
                playing={isIntroPlaying}
                url={intro}
                muted
                className={styles.video}
            /> */}
                <div
                    className={cn(styles.content, {
                        [styles.hide]: !isShowContent,
                    })}>
                    <p className={cn("subheader huge")}>
                        The great battle
                        <br /> between Life and Death!
                    </p>
                    <p className={cn("text wide big")}>
                        Which side will you be on?
                    </p>
                    <p className={cn("text normal")}>
                        And how much will you earn?
                    </p>
                    <div
                        onClick={() => continueVideo()}
                        className={styles.button}>
                        <Button
                            mainColor="primary"
                            type="complexShape"
                            classes="text normal wide"
                            rectsColor="#FF0049">
                            Start journey
                        </Button>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default IntroVideo;
