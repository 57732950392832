import styles from "./Delimiter.module.scss";
import cn from "classnames";

const Delimiter = ({ className, cssStyles }) => {
    return (
        <div
            style={cssStyles}
            className={cn(styles.container, className)}></div>
    );
};

export default Delimiter;
