import classNames from "classnames";
import React, { createContext, useRef, useState } from "react";
import Button from "../components/Button/Button";

import bg1 from "../assets/modalBgs/modalBg1.webp";
import bgMobile1 from "../assets/modalBgs/modalMobileBg1.webp";
import bg2 from "../assets/modalBgs/modalBg2.webp";
import bgMobile2 from "../assets/modalBgs/modalMobileBg2.webp";
import bg3 from "../assets/modalBgs/modalBg3.webp";
import bgMobile3 from "../assets/modalBgs/modalMobileBg3.webp";
import bg4 from "../assets/modalBgs/modalBg4.webp";
import bgMobile4 from "../assets/modalBgs/modalMobileBg4.webp";
import bg5 from "../assets/modalBgs/modalBg5.webp";
import bgMobile5 from "../assets/modalBgs/modalMobileBg5.webp";
import lifeCross from "../assets/crossLife.svg";
import bambooFrame from "../assets/roadmapBamboo.svg";
import modalBlur from "../assets/modalBlur.png";
import { useDispatch, useSelector } from "react-redux";
import { contentSlice } from "../store/reducers/contentReducer";

export const ModalContext = createContext({});

const BACKGROUNDS = [bg1, bg2, bg3, bg4, bg5];
const BACKGROUNDS_MOBILE = [
  bgMobile1,
  bgMobile2,
  bgMobile3,
  bgMobile4,
  bgMobile5,
];

const ModalState = ({ children }) => {
  const modalRef = useRef();
  const { isShowHeader, isModalOpened, isMobile, isSubMobile } = useSelector(
    (state) => state.contentReducer
  );
  const { updateIsModalOpened } = contentSlice.actions;
  const dispatch = useDispatch();
  const [isBottomContent, setIsBottomContent] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [geomarkId, setGeomarkId] = useState(0);
  const [isRoadmap, setIsRoadmap] = useState(false);
  const [isBlueBg, setIsBlueBg] = useState(false);
  const [isCrossNeeded, setIsCrossNeeded] = useState(true);

  const openModal = (
    content,
    isRoadmap = true,
    geoId = 0,
    isBottomContent = false,
    isBlueBg = false,
    isCrossNeeded = true
  ) => {
    setIsCrossNeeded(isCrossNeeded);
    setIsBlueBg(isBlueBg);
    setIsRoadmap(isRoadmap);
    setModalContent(content);
    setIsBottomContent(isBottomContent);
    setGeomarkId(geoId);
    modalRef.current.style.display = "flex";
    modalRef.current.style.zIndex = 200;
    modalRef.current.style.opacity = 1;
    dispatch(updateIsModalOpened(true));
  };

  const closeModal = () => {
    modalRef.current.style.opacity = 0;
    setTimeout(() => {
      modalRef.current.style.zIndex = -10;
      modalRef.current.style.display = "none";
      dispatch(updateIsModalOpened(false));
    }, 300);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, isModalOpened }}>
      {isShowHeader ? (
        <div
          ref={modalRef}
          className={classNames(
            "modal",
            {
              modalNoRoadmap: !isRoadmap,
            },
            { blueBg: isBlueBg },
            {
              active: isModalOpened,
            },
            {
              fixIsCrossNeeded: isRoadmap && isSubMobile,
            }
          )}
          onClick={(e) => e.target === e.currentTarget && closeModal()}
        >
          {isRoadmap &&
            !isMobile &&
            BACKGROUNDS.map((elem, i) => {
              return (
                <img
                  onClick={() => closeModal()}
                  key={i}
                  style={{
                    display: geomarkId === i ? "block" : "none",
                  }}
                  src={elem}
                  className="bg"
                  alt="background"
                />
              );
            })}
          {isRoadmap &&
            isMobile &&
            BACKGROUNDS_MOBILE.map((elem, i) => {
              return (
                <img
                  onClick={() => closeModal()}
                  key={i}
                  style={{
                    display: geomarkId === i ? "block" : "none",
                  }}
                  src={elem}
                  className="bg"
                  alt="background"
                />
              );
            })}
          {isCrossNeeded && (
            <div
              onClick={() => closeModal()}
              className={classNames(
                "modalCross",
                `modalCross${geomarkId}`,
                {
                  bottomCross: isBottomContent,
                },
                {
                  crossDesktop: !isMobile && !isSubMobile && !isRoadmap,
                }
              )}
            >
              <Button type="cross">
                <img src={lifeCross} alt="cross" />
              </Button>
            </div>
          )}
          {isRoadmap && !isSubMobile && (
            <div
              className={classNames("modalBody", `modalBody${geomarkId}`, {
                bottomBody: isBottomContent,
              })}
            >
              {modalContent}
            </div>
          )}
          {!isRoadmap && (
            <div
              onClick={(e) => e.target === e.currentTarget && closeModal()}
              className={classNames("modalBody modalBodyNoRoadmap", {
                modalDesktop: !isMobile,
              })}
            >
              {modalContent}
            </div>
          )}
          {isRoadmap && isSubMobile && (
            <div
              className={classNames(
                "modalBodySubmobile",
                `modalBodySubmobile${geomarkId}`
              )}
            >
              {isCrossNeeded && (
                <div
                  style={{
                    zIndex: 8,
                    position: "absolute",
                    top: "-2.5vw",
                    left: "44.8vw",
                    scale: "1.2",

                  }}
                  onClick={() => closeModal()}
                >
                  <Button type="cross">
                    <img src={lifeCross} alt="cross" />
                  </Button>
                </div>
              )}
              <img className="modalFrame" src={bambooFrame} alt="frame" />
              <img className="modalBlur" src={modalBlur} alt="frame" />
              {modalContent}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}

      {children}
    </ModalContext.Provider>
  );
};

export default ModalState;
