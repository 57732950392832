import styles from "./SlideWhiteList.module.scss";
import Timer from "../../Timer/Timer";
import Button from "../../Button/Button";
// import WhiteListModal from "../../WhiteListModal/WhiteListModal";
import { useContext } from "react";
import { ModalContext } from "../../../context/ModalState";

const SlideWhiteList = () => {
    const { openModal } = useContext(ModalContext);

    return (
        <div className={styles.container}>
            <p className="header big">GENESIS MINT</p>
            <Timer targetDate={Date.parse("14 JUN 2023 11:00:00 UTC")} />
            <div
                // onClick={() =>
                //     openModal(<WhiteListModal />, false, 0, false, true)
                // }
                onClick={()=>
                    setTimeout(() => {
                        window.open("https://mint.samurai-versus.io", "_blank")
                    })
                }
                className={styles.button}>
                <Button
                    mainColor="primary"
                    type="complexShape"
                    classes="text normal wide"
                    rectsColor="#FF0049">
                    {/* Read more */}
                    Samurai Mint
                </Button>
            </div>
        </div>
    );
};

export default SlideWhiteList;
