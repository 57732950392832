import styles from "./ClcModal.module.scss";
import cn from "classnames";

import clc1 from "../../assets/tokenomicsModal/clc1.svg";
import clc2 from "../../assets/tokenomicsModal/clc2.svg";
import clc3 from "../../assets/tokenomicsModal/clc3.webp";
import Delimiter from "../shapes/Delimiter/Delimiter";

const ClcModal = () => {
    return (
        <div className={styles.container}>
            <div className={styles.block1}>
                <p className={cn(styles.header, "text big wide colored")}>
                    The player who owns the CLC Token gets an advantage and can:
                </p>
                <div>
                    <div>
                        <img
                            src={clc1}
                            className={styles.image}
                            alt="info"
                        />
                        <p className={cn(styles.text, "text small")}>
                            mint NFT assets
                        </p>
                    </div>
                    <div>
                        <img
                            src={clc2}
                            className={styles.image}
                            alt="info"
                        />
                        <p className={cn(styles.text, "text small")}>
                            stake CLC Token to get a reward in the form of RYO
                            Token
                        </p>
                    </div>
                </div>
                <p className={cn(styles.hint, "text normal colored")}>
                    You cannot earn CLC Token in the game
                </p>
                <Delimiter className={styles.delimiter} />
            </div>
            <div className={styles.block2}>
                <p className={cn(styles.header, "text big wide colored")}>
                    $clc token distribution
                </p>
                <img
                    src={clc3}
                    alt="info"
                    className={styles.image}
                />
            </div>
        </div>
    );
};

export default ClcModal;
