import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    time: 0,
    days: 0,
    hours: 0,
    mins: 0,
    seconds: 0,
};

export const timerSlice = createSlice({
    name: "timer",
    initialState,
    reducers: {
        updateTimer(state, action) {
            console.log(action.payload);
            state.time = action.payload;
            state.days = Math.floor(state.time / (1000 * 60 * 60 * 24));
            state.hours = Math.floor(
                (state.time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            state.minutes = Math.floor(
                (state.time % (1000 * 60 * 60)) / (1000 * 60)
            );
            state.seconds = Math.floor((state.time % (1000 * 60)) / 1000);
        },
    },
});

export default timerSlice.reducer;
