import styles from "./SlideRoadmap.module.scss";
import cn from "classnames";

import bg from "../../../assets/bg3.webp";
import bgMobile from "../../../assets/bgRoadmapMobile.webp";
import cloud1 from "../../../assets/roadmapCloud1.png";
import cloud3 from "../../../assets/roadmapCloud2.png";
import lifeCross from "../../../assets/crossLife.svg";
import GeoMark from "../../GeoMark/GeoMark";
import { useContext } from "react";
import { ModalContext } from "../../../context/ModalState";
import { useSelector } from "react-redux";
import Button from "../../Button/Button";

const DATA_GEOMARKS = [
    { year: "2023", value: "q1" },
    { year: "2023", value: "q2" },
    { year: "2023", value: "q3" },
    { year: "2023", value: "q4" },
    { year: "2024", value: "q1" },
  ],
  DATA_MODALCONTENT = [
    <div className={styles.modalContent}>
      <p className="subheader normal wide">
        Community building, great deals for early members
      </p>
      <p className={cn("text normal wide", styles.subheader)}>
        Exclusive events:
      </p>
      <div className={styles.list}>
        <p className="text normal">
          <div className={cn(styles.checkbox, styles.active)}>
            <div className={styles.check}></div>
          </div>
          Recruitment of beta testers, encouraged by rewards in Ryo tokens
        </p>
        <p className="text normal">
          <div className={styles.checkbox}>
            <div className={styles.check}></div>
          </div>
          Samurai Genesis NFT limited pool sale (perpetual percentage of resales
          for the first owner).
        </p>
      </div>
    </div>,
    <div className={styles.modalContent}>
      <p className="subheader normal wide">
        Release of the &alpha;-version of the Samurai Versus application
        (without the Battle function)
      </p>
      <p className={cn("text normal wide", styles.subheader)}>
        Exclusive events:
      </p>
      <div className={styles.list}>
        <p className="text normal">
          <div className={styles.checkbox}>
            <div className={styles.check}></div>
          </div>
          The possibility of staking (and further crafting) Samurai and Heroes
          without spending Ryo and CLC tokens.
        </p>
      </div>
    </div>,
    <div className={styles.modalContent}>
      <p className="subheader normal wide">
        Release of the &beta;-version of the game (the group battles function
        and in-game tokenomics are connected)
      </p>
      <p className={cn("text normal wide", styles.subheader)}>
        Exclusive events:
      </p>
      <div className={styles.list}>
        <p className="text normal">
          <div className={styles.checkbox}>
            <div className={styles.check}></div>
          </div>
          Sale of a limited amount of RYO tokens for a quick start and payback
          in the game
        </p>
        <p className="text normal">
          <div className={styles.checkbox}>
            <div className={styles.check}></div>
          </div>
          The possibility of staking CLC tokens to receive RYO tokens as
          rewards.
        </p>
      </div>
    </div>,
    <div className={styles.modalContent}>
      <p className="subheader normal wide">Artifacts are added to the game</p>
      <p className={cn("text normal wide", styles.subheader)}>
        Exclusive events:
      </p>
      <div className={styles.list}>
        <p className="text normal">
          <div className={styles.checkbox}>
            <div className={styles.check}></div>
          </div>
          Distribution of a limited number of elite artifacts, one of a kind
        </p>
        <p className="text normal">
          {" "}
          <div className={styles.checkbox}>
            <div className={styles.check}></div>
          </div>
          Advertising integrations
        </p>
      </div>
    </div>,
    <div className={styles.modalContent}>
      <p className="subheader normal wide">
        Completion of the &beta;-phase, fights of Heroes 1x1 are added
      </p>
      <div className={styles.list}>
        <p className="text normal">
          <div className={styles.checkbox}>
            <div className={styles.check}></div>
          </div>
          Roadmap presentation of a new stage in the development of the Samurai
          Versus brand.
        </p>
      </div>
    </div>,
  ],
  POSITION_OF_CONTENT = ["top", "top", "top", "top", "bottom"];

const SlideRoadmap = () => {
  const { openModal } = useContext(ModalContext);

  const { isMobile, isModalOpened } = useSelector(
    (state) => state.contentReducer
  );

  return (
    <div
      id="roadmap"
      className={cn(styles.container, {
        [styles.padding]: isModalOpened,
      })}
    >
      <p
        className={cn(
          styles.header,
          {
            [styles.padding]: isModalOpened,
          },
          "header",
          "big"
        )}
      >
        Roadmap
      </p>
      {!isMobile && (
        <>
          <img src={bg} alt="background" className={styles.bg} />
          <img
            src={cloud1}
            alt="background"
            className={cn(styles.cloud, styles.cloud1)}
          />
          <img
            src={cloud3}
            alt="background"
            className={cn(styles.cloud, styles.cloud3)}
          />
        </>
      )}
      {isMobile && (
        <img src={bgMobile} alt="background" className={styles.bgMobile} />
      )}
      {DATA_GEOMARKS.map((elem, i) => {
        return (
          <div
            onClick={() =>
              openModal(
                <div>{DATA_MODALCONTENT[i]}</div>,
                true,
                i,
                POSITION_OF_CONTENT[i] === "bottom",
              )
            }
            className={`geomark geomark${i}`}
            key={i}
          >
            <GeoMark value={elem.value} year={elem.year} />
          </div>
        );
      })}
    </div>
  );
};

export default SlideRoadmap;
