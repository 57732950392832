import React from "react";
import DateTimeDisplay from "../DateTimeDisplay/DateTimeDisplay";
import { useCountdown } from "../../hooks/useCountdown";
import styles from "./Timer.module.scss";
import cn from "classnames";

const ShowCounter = ({ days, hours, minutes, seconds, isComing }) => {
  if (isComing)
    return (
      <div className={styles.container}>
        <DateTimeDisplay value={days > 0 ? days : 0} isComing={true} />
      </div>
    );

  return (
    <div className={styles.container}>
      <DateTimeDisplay
        value={days > 0 ? days : 0}
        isDays={true}
        type={"days"}
      />
      <DateTimeDisplay value={hours > 0 ? hours : 0} type={"hours"} />
      <p className={cn("header big", styles.dot)}>:</p>
      <DateTimeDisplay value={minutes > 0 ? minutes : 0} type={"min"} />
      <p className={cn("header big", styles.dot)}>:</p>
      <DateTimeDisplay value={seconds > 0 ? seconds : 0} type={"sec"} />
    </div>
  );
};

const Timer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        isComing={true}
      />
    );
  }

  return (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

export default Timer;
