import styles from "./ChronicleItem.module.scss";
import cn from "classnames";
import Button from "../../Button/Button";

import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { fixFontBug } from "../../../utils/fixFontBug";
import { useDispatch, useSelector } from "react-redux";
import { contentSlice } from "../../../store/reducers/contentReducer";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import texts from "../../../assets/chronicles/items/texts";

import prev from "../../../assets/arrowPrev.svg";
import glyph from "../../../assets/chroniclesGlyph.svg";

const ChronicleItem = () => {
    const [image, setImage] = useState();
    const { id } = useParams();
    const textRef = useRef();
    const imageRef = useRef();
    const containerRef = useRef();
    const textBlockRef = useRef();
    const [currentText, setCurrentText] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [countOfItems, setCountOfItems] = useState(0);

    const { isMobile, isSubMobile } = useSelector(
        (state) => state.contentReducer
    );
    const { updateIsIntroFinished } = contentSlice.actions;
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (
    //         containerRef.current &&
    //         textBlockRef.current &&
    //         window.innerWidth >= 768
    //     ) {
    //         containerRef.current.addEventListener("wheel", (e) => {
    //             textBlockRef.current.scroll({
    //                 top: textBlockRef.current.scrollTop + e.deltaY,
    //                 left: 0,
    //                 behavior: "smooth",
    //             });
    //         });
    //     }
    // }, [containerRef, textBlockRef]);

    useEffect(() => {
        dispatch(updateIsIntroFinished(true));
        console.log(
            Number(id) - 1 === 0,
            Number(id) + 1 === countOfItems + 1,
            countOfItems,
            Number(id),
            Number(id) + 1 === countOfItems + 1
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        textRef.current && fixFontBug(textRef.current);
        import(`../../../assets/chronicles/items/${id}.webp`).then((img) => {
            setImage(img);
            setCountOfItems(texts.length);
            setCurrentText(texts[id - 1].text);
        });
        imageRef.current &&
            (() => (imageRef.current.style.display = "block"))();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        console.log(isOpen);
    }, [isOpen]);

    return (
        <div
            ref={containerRef}
            className={cn(styles.container, `item${id}`)}>
            {isOpen && !isMobile && !isSubMobile && image && (
                <Lightbox
                    onCloseRequest={() => {
                        setIsOpen(false);
                    }}
                    mainSrc={image.default}
                />
            )}
            <img
                className={styles.glyph}
                src={glyph}
                alt="glyph"
            />
            <div className={styles.button}>
                <Link to="/chronicles">
                    <img
                        src={prev}
                        alt="icon"
                    />
                    <p className="text semibig colored wide">
                        Back to Chronicles
                    </p>
                </Link>
            </div>
            <div
                onClick={() => {
                    if (!isMobile && !isSubMobile) {
                        console.log("changed");
                        setIsOpen(true);
                    }
                }}
                className={styles.imgBlock}>
                {image && (
                    <img
                        className={styles.img}
                        ref={imageRef}
                        src={image.default}
                        alt="img"
                    />
                )}
            </div>
            <div className={styles.mainContent}>
                <div
                    ref={textBlockRef}
                    className={styles.textBlock}>
                    {texts && (
                        <>
                            <p className={cn(styles.header, "subheader big")}>
                                Chapter {id}. {texts[id - 1].title}
                            </p>
                            <p
                                ref={textRef}
                                className={cn(styles.text, "text small")}>
                                {currentText.replaceAll("<br>", "\n")}
                            </p>
                        </>
                    )}
                </div>
                <div
                    onClick={() => {
                        imageRef.current &&
                            (() => (imageRef.current.style.display = "none"))();
                        setCurrentText("");
                    }}
                    className={styles.buttons}>
                    <Link
                        onClick={(e) => {
                            if (Number(id) - 1 === 0) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        to={"/chronicles/" + String(Number(id) - 1)}>
                        <Button
                            disabled={Number(id) === 1}
                            classes="text small"
                            type="oneStrip">
                            Last Chapter
                        </Button>
                    </Link>
                    <Link
                        onClick={(e) => {
                            if (Number(id) + 1 === countOfItems + 1) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        to={"/chronicles/" + String(Number(id) + 1)}>
                        <Button
                            disabled={Number(id) === countOfItems}
                            classes="text small"
                            type="stripAndTwoBlocks">
                            Next Chapter
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ChronicleItem;
