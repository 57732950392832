import React from "react";
import styles from "./SlidePartner.module.scss";
import bgImg from "../../../assets/bg_partners.svg";

import bnbChain from "../../../assets/partners/bnb.svg";
import bitForex from "../../../assets/partners/bitforex.svg";

import salad from "../../../assets/partners/salad.svg";
import interfi from "../../../assets/partners/interfi.svg";
import cuties from "../../../assets/partners/cutes.svg";
import chronicles from "../../../assets/partners/chronicles.svg";

import cn from "classnames";

const SlidePartner = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div id="partner" className={styles.container}>
      <img src={bgImg} alt="background" className={styles.bg} />
      <img src={bgImg} alt="background" className={styles.bg2} />
      <div className={styles.content}>
        <div className={styles.content__big_banners}>
          <div
            className={styles.content__big_banners__item}
            onClick={() => openInNewTab("https://bscscan.com")}
          >
            <img
              src={bnbChain}
              alt="bnb"
              className={cn(
                styles.content__big_banners__item__logo,
                styles.bnb__logo
              )}
            />
            <div className={styles.content__big_banners__item__wrapper}>
              <span
                className={
                  styles.content__big_banners__item__wrapper__delimiter
                }
              ></span>
              <p
                className={cn(
                  "text small",
                  styles.content__big_banners__item__wrapper__title
                )}
                style={{ color: "white" }}
              >
                BSC blockchain
              </p>
            </div>
          </div>
          <div
            style={{ marginRight: "10px" }}
            className={styles.content__big_banners__item}
            onClick={() => openInNewTab("https://www.bitforex.com/")}
          >
            <img
              src={bitForex}
              alt="bitforex"
              className={styles.content__big_banners__item__logo}
              style={{
                marginTop: "30px",
              }}
            />
            <div className={styles.content__big_banners__item__wrapper}>
              <span
                className={
                  styles.content__big_banners__item__wrapper__delimiter
                }
              ></span>
              <p
                style={{
                  fontSize: "12px",
                  lineHeight: "17px",
                }}
                className={styles.content__big_banners__item__wrapper__title}
              >
                CEX for CLC Token
                <br />
                Incubator of Samurai Versus
              </p>
            </div>
          </div>
        </div>
        <div className={styles.content__small_banners}>
          <div
            className={styles.content__small_banners__item}
            onClick={() => openInNewTab("https://sales-and-ads.com/")}
          >
            <img
              src={salad}
              alt="bnb"
              className={styles.content__small_banners__item__logo}
              style={{ marginTop: "15px" }}
            />
            <div className={styles.content__small_banners__item__wrapper}>
              <span
                className={
                  styles.content__small_banners__item__wrapper__delimiter
                }
              ></span>
              <p
                className={cn(
                  "text small",
                  styles.content__small_banners__item__wrapper__title
                )}
              >
                Marketing agency <br />
                Advising
              </p>
            </div>
          </div>
          <div
            className={styles.content__small_banners__item}
            onClick={() => openInNewTab("https://www.interfi.network")}
          >
            <img
              src={interfi}
              alt="bnb"
              className={styles.content__small_banners__item__logo}
            />
            <div className={styles.content__small_banners__item__wrapper}>
              <span
                className={
                  styles.content__small_banners__item__wrapper__delimiter
                }
              ></span>
              <p
                className={cn(
                  "text small",
                  styles.content__small_banners__item__wrapper__title
                )}
              >
                Auditor
              </p>
            </div>
          </div>
          <div
            className={styles.content__small_banners__item}
            onClick={() => openInNewTab("https://blockchaincuties.com/")}
          >
            <img
              src={cuties}
              alt="bnb"
              className={styles.content__small_banners__item__logo}
            />
            <div className={styles.content__small_banners__item__wrapper}>
              <span
                className={
                  styles.content__small_banners__item__wrapper__delimiter
                }
              ></span>
              <p
                className={cn(
                  "text small",
                  styles.content__small_banners__item__wrapper__title
                )}
              >
                GameFi partner
              </p>
            </div>
          </div>
          <div
            className={styles.content__small_banners__item}
            onClick={() => openInNewTab("https://reptilechronicles.com")}
          >
            <img
              src={chronicles}
              alt="bnb"
              className={styles.content__small_banners__item__logo}
            />
            <div className={styles.content__small_banners__item__wrapper}>
              <span
                className={
                  styles.content__small_banners__item__wrapper__delimiter
                }
              ></span>
              <p
                className={cn(
                  "text small",
                  styles.content__small_banners__item__wrapper__title
                )}
              >
                GameFi partner
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlidePartner;
